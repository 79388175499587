if(typeof CRD !== 'undefined' && CRD.hasOwnProperty('BreakpointSpy')) {
	
	CRD.BreakpointSpy.prototype.addAllThese([
		
		// Default set
		[
			'xs',
			'(min-width: 0px)'
		],
		[
			'sm',
			'(min-width: 576px)'
		],
		[
			'md',
			'(min-width: 768px)'
		],
		[
			'lg',
			'(min-width: 992px)'
		],
		[
			'xl',
			'(min-width: 1200px)'
		]
	
	]);
	
}