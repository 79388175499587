/* jshint -W058 */

/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.ExpandableList = (function(context) {
	"use strict";
	
	// Variables
	var constants = {
			Mode      : {
				VISIBLE : 'visible',
				HIDDEN  : 'hidden'
			},
			Events    : {
				SHOW : 'crd.expandable.show',
				HIDE : 'crd.expandable.hide'
			}
		}, // Constants
		storage = {
			main  : 'crd.expandable',
			state : 'crd.expandable.state',
			html  : {
				main  : 'expandable',
				state : 'expanded'
			}
		}; // Data and HTML storage
	
	/**
	 * Expandable
	 * @class
	 * @param {String|Object} element - Main container for the expandable list
	 * @param {Object}        options - Options to override default submenu options.
	 * @return {Expandable}
	 */
	
	function Expandable(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data(storage.main); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			this.setOptions(options);
			
			/**
			 * Element to use as expandable list container
			 * @property {Object} list - List element
			 */
			
			this.list = element;
			
			/**
			 * List elements
			 * @property {Object} items - List elements collection
			 */
			
			this.items = jQuery(this.options.items, this.list);
			
			// Initializes the object
			this.Expandable();
			
			// Stores the object reference in the element storage
			this.list.data(storage.main, this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	// Expandable prototype implements ClassUtils
	
	Expandable.prototype = jQuery.extend({
		
		/**
		 * Default options
		 * @property {Object}   defaults              - Default options
		 * @property {String}   defaults.hideafter    - Maximum visible items when hidden
		 * @property {String}   defaults.items        - Selector for the list items
		 * @property {String}   defaults.speed        - Animation speed
		 * @property {String}   defaults.constructors - Interface constructors
		 * @property {String}   defaults.language     - Interface localization options
		 */
		
		defaults : {
			hideafter    : 4,
			items        : 'li',
			animated     : true,
			speed        : 100,
			constructors : {
				toggler : '<a href="javascript:;">{label}</a>'
			},
			language     : {
				more : 'Show more',
				less : 'Show less'
			}
		},
		
		/**
		 * Initializes the expandable list
		 * @constructor
		 * @memberof Expandable
		 * @public
		 */
		
		Expandable : function() {
			
			// If there are more items that the configured to be visible
			if(this.items.length > this.options.hideafter) {
				
				// Initializes the list
				this.create();
				
			}
			
		},
		
		/**
		 * Creates the expandable toggles and hooks all events
		 * @method create
		 * @return {Expandable}
		 * @memberOf Expandable
		 * @public
		 */
		
		create : function() {
			
			// For each item that must be hidden
			for(var x = this.options.hideafter, max = this.items.length; x < max; x = x + 1) {
				
				// Hides the item
				jQuery(this.items[x]).hide();
				
			}
			
			// Stores the initial state (hidden)
			this.list.data(storage.state, constants.Mode.HIDDEN);
			
			// Creates the toggler
			this.createToggler(constants.Mode.HIDDEN);
			
			// (:
			return this;
			
		},
		
		/**
		 * Creates the toggler with the corresponding text
		 * @method createToggler
		 * @param {String} mode - Mode (show/hide)
		 * @memberOf Expandable
		 * @public
		 */
		
		createToggler : function(mode) {
			
			// Variables
			var constructor = typeof this.options.constructors.toggler === 'object' && this.options.constructors.toggler.hasOwnProperty(mode) ?
					this.options.constructors.toggler[mode] :
					this.options.constructors.toggler,
				toggler = jQuery(constructor.substitute({
					label  : this.options.language[mode === constants.Mode.VISIBLE ? 'less' : 'more'],
					total  : this.items.length,
					hidden : this.items.length - this.options.hideafter
				}));
			
			// If the click handler is not defined
			if(typeof this.clickHandler === 'undefined') {
				
				// Creates the click handler for the toggler
				this.clickHandler = function(e) {
					e.preventDefault();
					this.toggle();
				}.bind(this);
				
			}
			
			// Adds the triggers event
			toggler.click(this.clickHandler);
			
			// If the toggler is not defined
			if(typeof this.toggler === 'undefined') {
				
				// Inserts the toggler after the list
				toggler.insertAfter(this.list);
				
			} else {
				
				// Replaces the current toggler with the new one
				this.toggler.replaceWith(toggler);
				
			}
			
			// Sets the toggler refernce
			this.toggler = toggler;
			
		},
		
		/**
		 * Toggles the list
		 * @method toggle
		 * @return {Expandable}
		 * @memberOf Expandable
		 * @public
		 */
		
		toggle : function() {
			
			// Variables
			var state = this.list.data(storage.state);
			
			// Shows or hide the content
			this[state === constants.Mode.VISIBLE ? 'hide' : 'show']();
			
			// (:
			return this;
			
		},
		
		/**
		 *
		 * @method show
		 * @fires CRD.Expandable.Events.SHOW
		 * @return {Expandable}
		 * @memberOf Expandable
		 * @public
		 */
		
		show : function() {
			
			// Sets the new state
			this.list.data(storage.state, constants.Mode.VISIBLE);
			
			// Replaces the toggler
			this.createToggler(constants.Mode.VISIBLE);
			
			// If transition must be animated
			if(this.options.animated === true) {
				
				// Animates the list
				this.animate(constants.Mode.VISIBLE)
				
			} else {
				
				// For each item that must be hidden
				for(var x = this.options.hideafter, max = this.items.length; x < max; x = x + 1) {
					
					// Hides the item
					jQuery(this.items[x]).show();
					
				}
				
			}
			
			/**
			 * Triggers the close event
			 * @event crd.submenu.close
			 */
			
			this.dispatch(constants.Events.SHOW, [
				this.list,
				this.items,
				this.toggler,
				this
			]);
			
			// (:
			return this;
			
		},
		
		/**
		 *
		 * @method hide
		 * @fires CRD.Expandable.Events.HIDE
		 * @return {Expandable}
		 * @memberOf Expandable
		 * @public
		 */
		
		hide : function() {
			
			// Sets the new state
			this.list.data(storage.state, constants.Mode.HIDDEN);
			
			// Replaces the toggler
			this.createToggler(constants.Mode.HIDDEN);
			
			// If transition must be animated
			if(this.options.animated === true) {
				
				// Animates the list
				this.animate(constants.Mode.HIDDEN)
				
			} else {
				
				// For each item that must be hidden
				for(var x = this.items.length - 1, max = this.options.hideafter - 1; x > max; x = x - 1) {
					
					// Hides the item
					jQuery(this.items[x]).hide();
					
				}
				
			}
			
			/**
			 * Triggers the close event
			 * @event crd.submenu.close
			 */
			
			this.dispatch(constants.Events.HIDE, [
				this.list,
				this.items,
				this.toggler,
				this
			]);
			
			// (:
			return this;
			
		},
		
		/**
		 * Animates the list items
		 * @method animate
		 * @param {String} mode - Animation mode
		 * @return {Expandable}
		 * @memberOf Expandable
		 * @public
		 */
		
		animate : function(mode) {
			
			// Variables
			var self = this,
				speed = this.options.speed,
				index, max, animate, animation;
			
			//
			if(mode === constants.Mode.VISIBLE) {
				
				// Set the variables
				index     = this.options.hideafter;
				max       = this.items.length;
				animation = 'slideDown'
				
			} else {
				
				// Set the variables
				index     = this.items.length - 1;
				max       = this.options.hideafter - 1;
				animation = 'slideUp'
				
			}
			
			// Creates the animation function
			function animate() {
				var current = index > max ? index-- : index++;
				jQuery(self.items[current] || [])[animation](speed, index === max ? null : animate);
			};
			
			// Animates items to hide
			animate();
			
			// (:
			return this;
			
		},
		
		/**
		 * Destroys the instance
		 * @method destroy
		 * @return {Expandable}
		 * @memberOf Expandable
		 * @public
		 */
		
		destroy : function() {
			
			// Removes the toggler
			this.toggler.remove();
			
			// For each element
			this.items.each(function(i, e) {
				
				// Normalizes element
				e = jQuery(e);
				
				// If the item is not visible
				if(e.is(':visible') === false) {
					
					// Shows the element
					e.show();
					
				}
				
			});
			
			// Removes the object reference from the element storage
			this.list.data(storage.main, null);
			
		}
		
	}, CRD.ClassUtils);
	
	// Set object constants
	CRD.Utils.setConstants(Expandable, constants);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(Expandable, 'expandable', storage.main);
	
	// Return the object reference
	return Expandable;
	
})();