if(typeof CRD === 'undefined') {
	var CRD = {};
}

// Creates the namepsace for the object
CRD.FixedNav = (function() {
	"use strict";
	
	// Variables
	var constants = {
			Mode : {
				OPEN  : 'open',
				CLOSE : 'close'
			}
		}, // Constants
		storage = 'crd.fixednav';
	
	/**
	 * FixedNav
	 * @class
	 * @param {String|Object} element - Element orselector string to apply the parallax effect.
	 * @param {Object}        options - Options to override default parallax options.
	 */
	
	function FixedNav(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data(storage); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			CRD.Utils.setOptions(this, options, this.defaults);
			
			/**
			 * Element to use as fixed navigation bar
			 * @property {Object} element - Element
			 */
			
			this.fixed = element;
			
			/**
			 * Initial menu state (not defined yet)
			 * @property {String} state - Menu state defined by constants: FixedNav.Mode.OPEN or FixedNav.Mode.CLOSE
			 */
			
			this.state = null;
			
			/**
			 * Active menu state (defined as not defined initially)
			 * @property {Boolean} active - Menu active status
			 */
			
			this.active = null;
			
			// Initializes the object
			this.FixedNav();
			
			// Stores the object reference in the element storage
			this.fixed.data(storage, this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	// Inherits from CRD.ScrollSpy
	FixedNav.inherit(CRD.ScrollSpy);
	
	// FixedNav prototype implements ClassUtils
	FixedNav.prototype = jQuery.extend({
		
		/**
		 * Default options
		 * @property {Object}          defaults                 - Default options
		 * @property {String}          defaults.initState       - Initial state (opened|closed)
		 * @property {Number}          defaults.delay           - Execution delay
		 * @property {Array}           defaults.conditions      - Condition that must be fullfilled in order to show or hide navigation
		 * @property {Boolean}         defaults.hideOnScroll    - Nav should hide on scroll down?
		 * @property {Object}          defaults.offset          - Top and bottom offsets to define a "hotzone" to show/hide the menu
		 * @property {Object}          defaults.position
		 * @property {Number|Function} defaults.position.closed - Positions for the closed state (function must return a number - related to object position)
		 * @property {Number|Function} defaults.position.opened - Positions for the opened state (function must return a number - related to object position)
		 * @property {Object}          defaults.classes
		 * @property {String}          defaults.classes.closed  - Class to apply to a closed nav
		 * @property {String}          defaults.classes.opened  - Class to apply to a opened nav
		 */
		
		defaults : {
			initState    : constants.Mode.CLOSE,
			delay        : 0,
			conditions   : [],
			hideOnScroll : true,
			offset       : null,
			positions    : {
				closed : function() {
					return -this.fixed.height();
				},
				opened : 0
			},
			classes      : {
				inactive : 'crd-fixednav-inactive',
				active   : 'crd-fixednav-active',
				closed   : 'crd-fixednav-closed',
				opened   : 'crd-fixednav-opened'
			},
			log          : false
		},
		
		/**
		 * Initializes FixedNav object
		 * @constructor
		 * @listen crd.scrollspy.up
		 * @listen crd.scrollspy.down
		 * @memberof FixedNav
		 */
		
		FixedNav : function() {
			
			// Scroll up handler
			this.scrollUpHandler = function(event, scrollY) {
				this.handleScroll(CRD.ScrollSpy.Scroll.UP, scrollY);
			}.debounce(this.options.delay).bind(this);
			
			// Scroll down handler
			this.scrollDownHandler = function(event, scrollY) {
				this.handleScroll(CRD.ScrollSpy.Scroll.DOWN, scrollY);
			}.debounce(this.options.delay).bind(this);
			
			// Set main events
			jQuery(new CRD.ScrollSpy())
				.on({
					'crd.scrollspy.up'   : this.scrollUpHandler,
					'crd.scrollspy.down' : this.scrollDownHandler
				});
			
			// If an initial state (open or closed) is set
			if(this.options.initState) {
				
				// Sets the initial navigation bar state
				this.switchMenu(this.options.initState);
				
			}
			
		},
		
		/**
		 * Handles scroll to perform menu show/hide actions
		 * @method handleScroll
		 * @param {String} direction - Scroll direction
		 * @param {Number} scrollY   - Current scroll position
		 * @memberof FixedNav
		 * @public
		 */
		
		handleScroll : function(direction, scrollY) {
			
			// Variables
			var offset  = {
					top    : this.getOffset('top', direction, scrollY), // Offset top (for avtive area)
					bottom : this.getOffset('bottom', direction, scrollY) // Offset bottom (for active area)
				},
				allowed = (offset.top === false || scrollY >= offset.top) && (offset.bottom === false || scrollY <= offset.bottom), // Show menu? Is menu between offset show/hide area?
				state   = false; // Default menu change state (no change)
			
			// If the new status is different than the new status
			if(allowed !== this.active) {
				
				/**
				 * Active/inactive states (according to area restrictions by offset)
				 * @event crd.fixednav.active
				 * @event crd.fixednav.inactive
				 */
				
				jQuery(this)
					.trigger('crd.fixednav.' + (allowed ? 'active' : 'inactive'), [
						this.fixed,
						this
					]);
				
				// Set the active or inactive status
				this.active = allowed ? true : false;
				
			}
			
			// If the fixed bar must hide on scroll
			if(this.options.hideOnScroll === true) {
				
				// If there are any conditions and all condition are met
				if(this.evalConditions(direction, scrollY) === true) {
					
					// If scrolling up and the scroll position is in the "hot zone" defined by offset
					if(direction === CRD.ScrollSpy.Scroll.UP && allowed === true) {
						
						// Shows the fixed bar
						state = constants.Mode.OPEN;
						
					}
					
					// If scrolling down or the scroll position is not in the "hot zone" defined by offset
					else if(direction === CRD.ScrollSpy.Scroll.DOWN || allowed === false) {
						
						// Hides the fixed bar
						state = constants.Mode.CLOSE;
						
					}
					
					// Switch menu state
					this.switchMenu(state);
					
				}
				
			}
			
			// Sets the active control classes
			this.fixed
				.removeClass(allowed ? this.options.classes.inactive : this.options.classes.active)
				.addClass(allowed ? this.options.classes.active : this.options.classes.inactive);
			
		},
		
		/**
		 * Show/hiddes menu
		 * @method switchMenu
		 * @param {String|Boolean} state - Current state (open|close)
		 * @fires crd.fixednav.open
		 * @fires crd.fixednav.close
		 * @memberof FixedNav
		 * @public
		 */
		
		switchMenu : function(state) {
			
			// If menu state must change
			if(state !== false && state !== this.state) {
				
				// variables
				var open = state === constants.Mode.OPEN, // Open?
					top  = this.getValue(open ? this.options.positions.opened : this.options.positions.closed);
				
				// Sets menu top position
				this.fixed
					.css('top', top)
					.addClass(open ? this.options.classes.opened : this.options.classes.closed)
					.removeClass(open ? this.options.classes.closed : this.options.classes.opened);
				
				// Stores menu state
				this.state = state;
				
				/**
				 * Triggers event
				 * @event crd.fixednav.open
				 * @event crd.fixednav.close
				 */
				
				jQuery(this)
					.trigger('crd.fixednav.' + state, [
						this.fixed,
						this
					]);
				
			}
			
		},
		
		/**
		 * Evaluates any possible user defined conditions
		 * @method evalConditions
		 * @param {String} direction - Scroll direction
		 * @param {Number} scrollY   - Scroll position
		 * @return {Boolean}
		 * @memberof FixedNav
		 * @public
		 */
		
		evalConditions : function(direction, scrollY) {
			
			// If there are any conditions
			if(this.options.conditions || this.options.conditions.length > 0) {
				
				// Variables
				var result; // default return value
				
				// For each condition
				for(var i = 0; i < this.options.conditions.length; i++) {
					
					// Executes condition (passing the current scroll direction and scroll position as arguments)
					result = this.options.conditions[i].apply(this, [direction, scrollY]);
					
					// If condition fails
					if(result === false) {
						
						// ):
						return false;
						
					}
					
				}
				
			}
			
			// if there arent any condition, then the condition is ment
			return true;
			
		},
		
		/**
		 * Get offset (top and bottom) that acts like "active arear" to show/hide menu.
		 * With a value lower than offset top or higher that offset bottom, the menu will hide.
		 * @method getOffset
		 * @param {String} offset    - Offset (top/bottom)
		 * @param {String} direction - Scroll direction (top/bottom)
		 * @param {Number} scrollY   - Scroll position
		 * @memberof FixedNavBar
		 * @return {Number|Boolean}
		 * @public
		 */
		
		getOffset : function(offset, direction, scrollY) {
			
			// Variables
			var value = this.options.offset && this.options.offset.hasOwnProperty(offset) ? this.options.offset[offset] : false; // Offset defined?
			
			// if offset is defined
			if(value !== false) {
				
				// returns the value
				return this.getValue(value, [direction, scrollY]);
				
			}
			
			// Returns default value
			return false;
			
		},
		
		/**
		 * Computes a value. Supplied can be a number or a function (will be executed binded to the current object).
		 * @method getValue
		 * @param {Function|Number|Boolean} value  - Value to parse (function will be exectued)
		 * @param {Object}                  params - Params for the function
		 * @return {Number}
		 * @memberOf FixedNav
		 * @public
		 */
		
		getValue : function(value, params) {
			
			// If defined offset is a function
			if(typeof value === 'function') {
				
				// executes the function and returns the returned value
				return value.apply(this, params || []);
				
			}
			
			// If defined offset if numeric
			else {
				
				// (:
				return value;
				
			}
			
		},
		
		/**
		 * Destoys the object and unhooks the events
		 * @method destroy
		 * @memberOf FixedNav
		 * @public
		 */
		
		destroy : function() {
			
			// Unhooks ScrollSpy events
			// fix-me : if scrollspy is a independent instance, it should not destroys it
			// CRD.ScrollSpy.prototype.destroy.apply(this);
			
			// Removes the events from the ScrollSpy instance
			jQuery(new CRD.ScrollSpy())
				.off({
					'crd.scrollspy.up'   : this.scrollUpHandler,
					'crd.scrollspy.down' : this.scrollDownHandler
				});
			
			// Removes the object reference from element storage
			this.fixed.removeData(storage);
			
		}
		
	}, CRD.ClassUtils);
	
	// Set object constants
	CRD.Utils.setConstants(FixedNav, constants);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(FixedNav, 'fixednav', storage);
	
	// Retruns the constructor
	return FixedNav;
	
})();