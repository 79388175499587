/* jshint -W058 */

/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.PuzzleImageGrid = (function() {
	
	// Variables
	var constants = {
			Events    : {
				DRAW         : 'crd.puzzleimagegrid.draw',
				IMAGE_LOADED : 'crd.puzzleimagegrid.imageloaded'
			}
		}, // Constants
		storage = {
			main  : 'crd.puzzleimagegrid',
			html  : {
				main  : 'puzzleimagegrid'
			}
		}, // Data and HTML storage
		extend = jQuery.extend;
	
	/**
	 * Puzzle
	 * @class
	 * @param {String|Object} element - Main container for the grid
	 * @param {Object}        images  - Gallery images array
	 * @param {Object}        options - Options to override default grid options
	 * @return {Puzzle}
	 */
	
	function Puzzle(element, images, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data(storage.main); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			this.setOptions(options);
			
			/**
			 * Element to use as container
			 * @property {Object} element - Element
			 */
			
			this.element = element;
			
			/**
			 * Images
			 * @property {Object} images - Original images reference
			 */
			
			this.images = images;
			
			/**
			 * Last image displayed (for paging purposes)
			 * @property {Object} lastindex - Last image rendered
			 */
			
			this.lastindex = 0;
			
			// Initializes the object
			this.Puzzle();
			
			// Stores the object reference in the element storage
			this.element.data(storage.main, this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	// Puzzle prototype implements ClassUtils
	Puzzle.prototype = extend({
		
		/**
		 * Default options
		 * @property {Object}      defaults            - Default options
		 * @property {Boolean}     autoresize          - Automatic gallery resizing on window resize
		 * @property {Int}         targetheight        - Images target height (if percentage based is enabled, the value will be a percentage of the available width)
		 * @property {Boolean}     percentage          - Enables/disables percentage based grid
		 * @property {Int}         gap                 - Gap between images
		 * @property {Int|Boolean} visiblerows         - Visible rows
		 * @property {Int}         pagesize            - Page size (in rows)
		 * @property {Boolean}     animated            - Animated new rows?
		 * @property {Number}      speed               - Animation speed
		 */
		
		defaults : {
			autoresize   : false,
			targetheight : 200,
			percentage   : false,
			gap          : 5,
			visiblerows  : 2,
			pagesize     : 2,
			animated     : true,
			speed        : 250,
			constructors : {
				row   : '<div class="row" />',
				image : '<div class="col-auto"><img class="puzzle-image" /></div>'
			}
		},
		
		/**
		 * Initializes the image gris
		 * @constructor
		 * @memberof Puzzle
		 * @public
		 */
		
		Puzzle : function() {
			
			// If automatic resize is enabled
			if(this.options.autoresize === true) {
				
				// Sets event handler function
				this.resizeHandler = this.draw.bind(this, [true]);
				
				// Hooks to resize event
				jQuery(window)
					.resize(this.resizeHandler);
				
			}
			
			// Draws the grid
			this.draw();
		
		},
		
		/**
		 * Draws the image grid
		 * @method draw
		 * @fires CRD.PuzzleImageGrid.Events.DRAW
		 * @param {...*} passed - 'replace' or 'to, replace' or 'from, to, replace'
		 * 						  {Boolean|String} replace - Replaces current draw or append the result (default)
		 * 						  							 Accepted keywords:
		 * 						  							 - 'next page' : loads next page
		 * 						  {Int}            from    - Starting image number to draw
		 * 						  {Int}            to      - Ending image to draw
		 * @memberof Puzzle
		 * @public
		 */
		
		draw : function(passed) {
			
			// Variables
			var args = Array.prototype.slice.call(arguments),
				showrows,
				replace, from, to,
				images, rows, grid;
			
			// Normalize arguments
			replace = args.pop() || false;
			to      = args.pop(); to   = typeof to === 'undefined' ? this.images.length : to;
			from    = args.pop(); from = typeof from === 'undefined' ? this.lastindex : from;
			
			// Set the defult rows to show
			showrows = replace === true ? this.options.visiblerows : false;
			
			// If first argument is a string (special keywords)
			if(typeof replace === 'string') {
				
				// Switch between different keywords
				switch(replace) {
					
					// Next page
					case 'next page':
						
						// Force all images to be parsed, starting from last index
						to   = this.images.length;
						from = this.lastindex
						
						// Sets the rows to show
						showrows = this.options.pagesize;
						
						// Append, not replace
						replace = false;
						
						break;
					
				}
				
			}
			
			// Set images and rows
			images = this.getImages(from, to);
			rows   = this.buildRows(images, showrows);
			
			// Sets the last rendered index
			this.lastindex = from + this.getImageCount(rows);
			
			// For each row
			for(var x = 0, max = rows.length; x < max; x = x + 1) {
			
				// Fit row images in available space
				rows[x] = this.fitInRow(rows[x]);
			
			}
			
			// Render the grid
			grid = this.render(rows);
			
			// If not replacing the gallery and new rows must animate
			if(replace === false && this.options.animated === true) {
				
				// For each row
				for(x = 0, max = grid.length; x < max; x = x + 1) {
					
					// Hide row
					jQuery(grid[x]).hide();
					
				}
				
				// Appends the grid to the container
				this.element.append(grid);
				
				// Animate rows
				this.animate(grid);
				
			} else {
				
				// Appends the grid to the container
				this.element[replace === true ? 'html' : 'append'](grid);
				
			}
			
			/**
			 * Triggers the draw event
			 * @event crd.puzzleimagegrid.draw
			 */
			
			this.dispatch(constants.Events.DRAW, [
				from,
				this.lastindex,
				this.images.length,
				this.element,
				this
			]);
		
		},
		
		/**
		 * Build puzzle rows
		 * @method render
		 * @param {Object} rows - Rows of image arrays
		 * @memberof Puzzle
		 * @public
		 * @return {Object}
		 */
		
		render : function(rows) {
			
			// Variables
			var self   = this,
				row    = jQuery(this.options.constructors.row),
				grid = [],
				crow, images, constructor, image;
			
			// For each row
			for(var x = 0, max = rows.length; x < max; x = x + 1) {
				
				// Resets images
				images = [];
				
				// Creates current row
				crow = row.clone();
				
				// For each image
				for(var y = 0, maxy = rows[x].length; y < maxy; y = y + 1) {
					
					// If any extra params are defined, replace the params, otherwise uses the default constructor
					constructor = rows[x][y].hasOwnProperty('extra') === true ? this.options.constructors.image.substitute(rows[x][y].extra) : this.options.constructors.image;
					
					// Creates the image
					image = jQuery(constructor);
					
					// Set images attributes
					image.find('img')
						.css({
							'width'  : rows[x][y].width + 'px',
							'height' : rows[x][y].height + 'px'
						})
						.on('load', function(event) {
							self.dispatch(constants.Events.IMAGE_LOADED, [
								jQuery(this),
								self
							]);
						})
						.attr('src', rows[x][y].src);
					
					// Push the image to the current row images array
					images.push(image);
					
				}
				
				// Append images to the current row
				crow.append(images);
				
				// Pushe the rows to the rows array
				grid.push(crow);
				
			}
			
			// Returns the built grid
			return grid;
			
		},
		
		/**
		 * Animates rows
		 * @method animate
		 * @param {Object} rows - Rows to animate
		 * @return {Puzzle}
		 * @memberOf Puzzle
		 * @public
		 */
		
		animate : function(rows) {
		
			// Variables
			var speed = this.options.speed,
				index = 0,
				max   = rows.length,
				animate;
			
			// Creates the animation function
			function animate() {
				var current = index++;
				jQuery(rows[current] || []).slideDown(speed, index === max ? null : animate);
			};
			
			// Animates items to hide
			animate();
			
			// (:
			return this;
			
		},
		
		/**
		 * Build puzzle rows
		 * @method buildRows
		 * @param {Object}      images  - Pased image array
		 * @param {Int|Boolean} maxrows - Max number of rows to parse
		 * @memberof Puzzle
		 * @public
		 * @return {Object}
		 */
		
		buildRows : function(images, maxrows) {
		
			// Variables
			var maxwidh = this.element.width(),
				row     = 0,
				width   = 0,
				image   = 0,
				rows    = [];
			
			// While images are available
			while(images[image] && (maxrows === false || row < maxrows)) {
				
				// Current row
				rows[row] = rows[row] || [];
				
				// If the row is completed
				if(width >= maxwidh) {
					
					// Resets the row cumulative width
					width = 0;
					
					// Next row!
					row++;
					continue;
					
				}
				
				// Adds the image to the current row
				rows[row].push(images[image]);
				
				// Cumulative width
				width += images[image].width;
				
				// Next image!
				image++;
				
			}
			
			// (:
			return rows;
		
		},
		
		/**
		 * Fit images in current available width
		 * @method fitInRow
		 * @param {Object} images - Pased image array
		 * @memberof Puzzle
		 * @public
		 * @return {Object}
		 */
		
		fitInRow : function(images) {
			
			// Variables
			var totalwidth  = this.element.width(),
				width       = this.getRowWidth(images),
				max         = 20,
				current     = 0;
			
			// While row is larger than the available width
			while(width > totalwidth && current < max) {
				
				// Makes the row smaller
				images = this.makeRowSmaller(images, totalwidth, width);
				
				// Computes the new row width
				width = this.getRowWidth(images);
				
				// Anti freeze :P
				current++;
				
			}
			
			// (:
			return images;
			
		},
		
		/**
		 * Reduces all image width to fit the maximum row width
		 * @method makeRowSmaller
		 * @param {Object} images       - Parsed image array
		 * @param {Number} totalwidth   - Available width
		 * @param {Number} currentwidth - Total width of current row
		 * @memberof Puzzle
		 * @public
		 * @return {Object}
		 */
		
		makeRowSmaller : function(images, totalwidth, currentwidth) {
			
			// Variables
			var percentage;
			
			// For each supplied image
			for(var x = 0, max = images.length; x < max; x = x + 1) {
				
				// Gets the percentage the current image takes from the entire row
				percentage = (images[x].width * 100) / currentwidth;
				
				// Computes and sets the new image size
				images[x] = extend(images[x], this.computeImageSize(images[x], images[x].width - ((currentwidth - totalwidth) * (percentage / 100)), null));
				
			}
			
			// (:
			return images;
		
		},
		
		/**
		 * Draws the image grid
		 * @method getImages
		 * @param {Int} from - Starting image to process
		 * @param {Int} to   - Ending image to process
		 * @memberof Puzzle
		 * @public
		 * @return {Object}
		 */
		
		getImages : function(from, to) {
			
			// Variables
			var containerwidth = this.element.width(),
				result         = [],
				height
			
			// For each image
			for(var x = from, max = Math.min(this.images.length, Number(to)), images = this.images; x < max; x = x + 1) {
				
				// If in percentage mode
				if(this.options.percentage === true) {
					
					// Sets the height of the image
					height = containerwidth * (this.options.targetheight / 100);
				
				} else {
					
					// Sets the height of the image
					height = this.options.targetheight;
					
				}
				
				// Adds each processed image to the imge result array
				result.push(extend(true, {}, images[x], this.computeImageSize(images[x], null, height), {
					src : images[x].src
				}));
			
			}
			
			// Returns the processed images array
			return result;
			
		},
		
		/**
		 * Count total images for a set of rows
		 * @method getImageCount
		 * @param {Object} rows - Rows to count
		 * @memberof Puzzle
		 * @public
		 * @return {Number}
		 */
		
		getImageCount : function(rows) {
			
			// Variables
			var count = 0;
			
			// For each row
			for(var x = 0, max = rows.length; x < max; x = x + 1) {
				
				// Adds the row count
				count += rows[x].length;
				
			}
			
			// (:
			return count;
			
		},
		
		/**
		 * Calculates the image size for a specific height, using original aspect ratio
		 * @method computeImageSize
		 * @param {Object} original        - Object containing original image size
		 * @param {Number} original.width  - Image width
		 * @param {Number} original.height - Image height
		 * @param {Boolean|Number} height  - Target height
		 * @param {Boolean|Number} width   - Target width
		 * @memberof Puzzle
		 * @public
		 * @return {Object}
		 */
		
		computeImageSize : function(original, width, height) {
			
			// If computed based on width
			if(width !== null && width !== false) {
				
				// (:
				return {
					width  : width,
					height : original.height * (width / original.width)
				};
				
			} else {
				
				// (:
				return {
					width  : original.width * (height / original.height),
					height : height
				};
				
			}
			
		},
		
		/**
		 * Computes the cumulative width of all supplied images plus image gap
		 * @method getRowWidth
		 * @param {Object} images - Parsed image array
		 * @memberof Puzzle
		 * @public
		 * @return {Number}
		 */
		
		getRowWidth : function(images) {
		
			// Variables
			var width = 0;
			
			// For each supplied image
			for(var x = 0, max = images.length; x < max; x = x + 1) {
				
				// Adds the image width
				width += images[x].width;
				
			}
			
			// Adds all the gaps
			width += this.options.gap * (images.length - 1);
			
			// (:
			return width;
		
		},
		
		/**
		 * Parses a provided value and searches for a unit (only percentages are accepted)
		 * @method parseValue
		 * @param {*} value - Value to parse, could be a number or a string with a percentage
		 * @memberof Puzzle
		 * @public
		 * @return {Number|Object}
		 */
		
		parseValue : function(value) {
			
			// Variables
			var parsed, unit;
			
			// If the value is not a number
			if(isNaN(value) || !isFinite(value)) {
				
				// Parse number and unit
				parsed = parseFloat(value);
				unit   = value.replace(parsed, '').trim();
				
				// It will only accept percentages as units
				if(unit === '%') {
					
					// (:
					return {
						value : parsed,
						unit  : '%'
					};
					
				} else {
					
					// Everything else is a number
					return parsed;
					
				}
			
			} else {
				
				// (:
				return Number(value);
				
			}
			
		}
		
	}, CRD.ClassUtils);
	
	// Set object constants
	CRD.Utils.setConstants(Puzzle, constants);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(Puzzle, 'puzzleImageGrid', storage.main);
	
	// Return the object reference
	return Puzzle;
	
})();