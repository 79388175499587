// Self executing function
(function(context) {
	"use strict";
	
	/**
	 * Creates namespaces from given string representations (like: App.Functions.Global)
	 * @param {String} namespace - Namespace to create (dot separated values)
	 * @return {Object}
	 * @public
	 */
	
	function namespace(namespace) {
		
		// Variables
		var stack = context;
		
		// Splits the namespace
		namespace = namespace.split('.');
		
		// For each defined namespace
		for(var i = 0, max = namespace.length; i < max; i++) {
			
			// If the namespace doesnt exist in the namespace stack
			if(typeof stack[namespace[i]] === 'undefined') {
				
				// Creates the namespace
				stack[namespace[i]] = {};
				
			}
			
			// Updates the stack reference
			stack = stack[namespace[i]];
			
		}
		
		// (:
		return stack;
		
	}
	
	// Populates the function
	context.namespace = namespace;
	
})(window || this);