/* jshint -W058 */

/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.Collapsable = (function(context) {
	"use strict";
	
	// Variables
	var constants = {
			Mode      : {
				OPEN  : 'open',
				CLOSE : 'close'
			},
			Events    : {
				OPEN   : 'crd.collapsable.open',
				CLOSE  : 'crd.collapsable.close',
				TOGGLE : 'crd.collapsable.toggle'
			}
		}, // Constants
		storage = {
			main  : 'crd.collapsable',
			state : 'crd.collapsable.state',
			html  : {
				main  : 'collapsable',
				state : 'collapsed'
			}
		}; // Data and HTML storage
	
	/**
	 * Collapsable
	 * @class
	 * @param {String|Object} element - Main container for the togglers and content
	 * @param {Object}        options - Options to override default submenu options.
	 * @return {Collapsable}
	 */
	
	function Collapsable(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data(storage.main); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			this.setOptions(options);
			
			/**
			 * Element to use as container
			 * @property {Object} element - Element
			 */
			
			this.element = element;
			
			/**
			 * Toggle triggers
			 * @property {Object} triggers - Toggle triggers collection
			 */
			
			this.triggers = jQuery(this.options.triggers, this.element);
			
			/**
			 * Toggle containers
			 * @property {Object} containers - Toggle containers collection
			 */
			
			this.containers = jQuery(this.options.containers, this.element);
			
			// Initializes the object
			this.Collapsable();
			
			// Stores the object reference in the element storage
			this.element.data(storage.main, this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	// Collapsable prototype implements ClassUtils
	Collapsable.prototype = jQuery.extend({
		
		/**
		 * Default options
		 * @property {Object}   defaults            - Default options
		 * @property {String}   defaults.triggers   - Selector for the triggers
		 * @property {String}   defaults.containers - Selector for the containers
		 * @property {Function} defaults.show       - Function to show containers (inmediately)
		 * @property {Function} defaults.hide       - Function to hide containers (inmediately)
		 * @property {Function} defaults.toggle     - Function to toggle containers (inmediately)
		 */
		
		defaults : {
			triggers    : '.trigger',
			containers  : '.container',
			show        : function() { this.show(); },
			hide        : function() { this.hide(); },
			toggle      : function() { this.slideToggle(); }
		},
		
		/**
		 * Initializes the togglers
		 * @constructor
		 * @memberof Collapsable
		 * @public
		 */
		
		Collapsable : function() {
			
			// Variables
			var self = this;
			
			// For each defined container
			this.containers.each(function(i, container) {
				
				// Variables
				var state, mode;
				
				// Container
				container = jQuery(container);
				
				// Get the html data atribute
				state = container.data(storage.html.state);
				
				// If the  state is defined
				if(typeof state !== 'undefined' && (state === constants.Mode.OPEN || Number(state) === 0)) {
					
					// Shows the container
					self.options.show.apply(container);
					
					// Sets the state as opened
					mode = constants.Mode.OPEN;
					
				} else {
					
					// Hides the container
					self.options.hide.apply(container);
					
					// Sets the state as closed
					mode = constants.Mode.CLOSE;
					
				}
				
				// Sets the toggle state
				container.data(storage.state, mode);
				
				/**
				 * Triggers the resize event
				 * @event crd.collapsable.open
				 * @event crd.collapsable.close
				 */
				
				self.dispatch(mode === constants.Mode.OPEN ? constants.Events.OPEN : constants.Events.CLOSE, [
					jQuery(self.triggers[i]),
					container,
					self
				]);
				
			});
			
			// Creates the click handler for the triggers
			this.clickHandler = function(e) {
				e.preventDefault();
				this.toggle(e.currentTarget);
			}.bind(this);
			
			// Adds the triggers event
			this.triggers.click(this.clickHandler);
			
		},
		
		/**
		 * Toggles a specific content
		 * @method toggle
		 * @param {Object|Number} trigger - Trigger or index (numeric) for the content to toggle
		 * @fires CRD.Collapsable.Events.OPEN
		 * @fires CRD.Collapsable.Events.CLOSE
		 * @return {Collapsable}
		 * @memberOf Collapsable
		 * @public
		 */
		
		toggle : function(trigger) {
			
			// Element
			trigger = jQuery(typeof trigger === 'number' ? this.triggers[trigger] : trigger);
			
			// Variables
			var container = trigger.next(this.options.containers), // Container
				state = container.data(storage.state), // Current state
				mode = constants.Mode.OPEN,
				event = constants.Events.OPEN;
			
			// If container is opened
			if(state === constants.Mode.OPEN) {
				
				// Set the new mode and the event to trigger
				mode  = constants.Mode.CLOSE;
				event = constants.Events.CLOSE;
				
			}
			
			// Sets the new state
			container.data(storage.state, mode);
			
			// Applies show/hide function to the menu
			this.options.toggle.apply(container, [mode]);
			
			/**
			 * Triggers the open or close event
			 * @event crd.collapsable.open
			 * @event crd.collapsable.close
			 */
			
			this.dispatch(event, [
				trigger,
				container,
				this
			]);
			
			// (:
			return this;
			
		},
		
		/**
		 * Closes a specific container determined by its index (or position in the array of containers)
		 * @method close
		 * @param {Number} index - Index of the container to close
		 * @fires CRD.Collapsable.Events.CLOSE
		 * @return {Collapsable}
		 * @memberOf Collapsable
		 * @public
		 */
		
		close : function(index) {
			
			// Variables
			var container = jQuery(this.containers[index]),
				trigger = jQuery(this.triggers[index]);
			
			// Sets the new state
			container.data(storage.state, constants.Mode.CLOSE);
			
			// closes the container
			this.options.hide.apply(container);
			
			/**
			 * Triggers the close event
			 * @event crd.submenu.close
			 */
			
			this.dispatch(constants.Events.CLOSE, [
				trigger,
				container,
				this
			]);
			
			// (:
			return this;
			
		},
		
		/**
		 * Close all the submenus
		 * @method closeAll
		 * @param {Boolean} toggle - Use animated close (with the defined toggle function)
		 * @return {Collapsable}
		 * @memberOf Collapsable
		 * @public
		 */
		
		closeAll : function(toggle) {
			
			// Variables
			var self = this;
			
			// Animated?
			toggle = toggle || false;
			
			// For each submenu
			this.containers.each(function(i, container) {
				
				// If must use animation
				if(toggle === true && jQuery(container).data(storage.state) === constants.Mode.OPEN) {
					
					// Toggles to close the container
					self.toggle(i);
					
				} else {
					
					// Close the container
					self.close(i);
					
				}
				
			});
			
			// (:
			return this;
			
		}
		
	}, CRD.ClassUtils);
	
	// Set object constants
	CRD.Utils.setConstants(Collapsable, constants);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(Collapsable, 'collapsable', storage.main);
	
	// Return the object reference
	return Collapsable;
	
})();