/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.BreakpointActions = (function() {
	"use strict";
	
	/**
	 * BreakpointActions
	 * Executes a set of defined functions each time a breakpoint changes
	 * @class
	 * @param {Object} options - Options to override default breakpoint actions options.
	 * @return {BreakpointActions}
	 */
	
	function BreakpointActions(options) {
		
		// If singleton has not been created
		if(BreakpointActions.prototype.instance === null) {
			
			// Set options and events
			this.setOptions(options);
			
			// Run main script
			this.BreakpointActions();
			
			// Sets singleton
			BreakpointActions.prototype.instance = this;
			
		}
		
		// Returns singleton instance
		return BreakpointActions.prototype.instance;
		
	}
	
	// BreakpointActions prototype implements ClassUtils
	BreakpointActions.prototype = jQuery.extend({
		
		/**
		 * Singleton instance object
		 * @property {Object} instance - Singleton pattern instance
		 */
		
		instance : null,
		
		/**
		 * Used breakpointspy instance
		 * @property {Object} instance - CRD.BreakpointSpy instance
		 */
		
		spy : null,
		
		/**
		 * Default options
		 * @property {Object} defaults         - Default options
		 * @property {Object} defaults.actions - Set of actions to execute
		 */
		
		defaults : {
			actions : []
		},
		
		/**
		 * Initializes the breakpoint spy
		 * @constructor
		 * @memberof BreakpointActions
		 * @public
		 */
		
		BreakpointActions : function() {
			
			// Create handlers for BreakpointSpy object
			this.switchHandler = function(e, changed, breakpoint, hdpi, spy) {
				
				// Auto-set the spy instance (so the actions can be initialized prior to spy initialization
				if(this.spy === null) {
					this.spy = spy;
				}
				
				// Perform all actions
				this.performActions(changed, breakpoint, hdpi);
				
			}.bind(this);
			
			// Hooks to BreakpointSpy change event
			jQuery(document)
				.on('crd.breakpointspy.always', this.switchHandler);
		
		},
		
		/**
		 * Add a new action
		 * @method add
		 * @param {function} action - Action to perform
		 * @returns {CRD.BreakpointActions}
		 * @memberOf BreakpointActions
		 * @return {BreakpointActions}
		 */
		
		add : function(action, force) {
			
			// Add action
			this.options.actions.push(action);
			
			// If need to force execution
			if(force === true && this.spy !== null) {
				
				// Executes the action
				action.apply(null, [true, this.spy.breakpoint.current, this.spy.breakpoint.hdpi])
				
			}
			
			// (:
			return this;
			
		},
		
		/**
		 * Executes specific breakpoint actions
		 * @method performActions
		 * @param {boolean} changed    - Breakpoint has changed since last execution?
		 * @param {string}  breakpoint - Current breakpoint
		 * @param {boolean} hdpi       - Is HDPI?
		 * @memberof BreakpointActions
		 */
		
		performActions : function(changed, breakpoint, hdpi) {
			
			// Variables
			var x, max;
			
			// For each action to execute
			for(x = 0, max = this.options.actions.length; x < max; x = x + 1) {
				
				// Executes the function
				this.options.actions[x].apply(null, [changed, breakpoint, hdpi, this]);
				
			}
			
		}
		
	}, CRD.ClassUtils);
	
	// Return the object reference
	return BreakpointActions;
	
})();