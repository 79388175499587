/* jshint -W058 */

/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

(function() {
	"use strict";
	
	// Utilities namespace
	CRD.Utils = {
		
		/**
		 * Set object or class options in Object.options property. If the defined options has an 'on' property, it will
		 * bind all the defined events as object or class events. The 'on' property must contain a set of key and values,
		 * where key is the event name and the value contains the callback function. All callback will be automatically
		 * binded to the object.
		 * @param {Function|Object} object   - Object where options will be setted.
		 * @param {Object}          options  - Object containing options to set.
		 * @param {Object}          defaults - Default options for the object or class.
		 * @return {Object|Function}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		setOptions : function(object, options, defaults) {
			
			// Variables
			var events;
			
			// If defined options have an 'on' property (reserved for events)
			if(typeof options === 'object' && options.hasOwnProperty('on') && typeof options.on === 'object') {
				
				// Store events in a variable
				events = options.on;
				
				// Removes reserved property from options
				delete options.on;
				
				// For each defined event
				for(var i in events) {
					
					// Filter unwanted properties and check for event to trigger only a function
					if(events.hasOwnProperty(i) && typeof events[i] === 'function') {
						
						// Set the defined event and binds the event to the current object or class
						jQuery(object).on(i, events[i].bind(object));
						
					}
					
				}
				
			}
			
			// Set object or class options; it will use any prior object options (if defined), default or empty object
			object.options = jQuery.extend(true, {}, object.options || defaults || {}, options);
			
			// (:
			return object;
			
		},
		
		/**
		 * Define constants for an object, function or class, using Object.defineProperty
		 * @param {Object|Function} object - Object or function where the constants will be stored
		 * @param {Object} constants             - Constants object
		 * @memberof CRD.Utils
		 * @public
		 */
		
		setConstants : function(object, constants) {
			
			// For each defined constant
			for(var c in constants) {
				
				// Filter unwanter properties
				if(constants.hasOwnProperty(c)) {
					
					switch(typeof constants[c]) {
						
						// If value is an object
						case 'object':
							
							// Creates the object
							object[c] = {};
							
							// Create nested constants
							CRD.Utils.setConstants(object[c], constants[c]);
							
							break;
							
						// Default
						default:
							
							// Sets setter and getter for the object
							Object.defineProperty(object, c, {
								value : constants[c]
							});
							
							break;
						
					}
					
				}
				
			}
			
		},
		
		/**
		 * Defines helpers to create Class or Function instances on the fly using jQuery selectors. This function is
		 * coded to work with the class definition proposed in other object of the CRD Library.
		 * @param {Object|Function} object  - Obect that the helper will create
		 * @param {String}          helper  - Helper name (to use as jQuery(selecto).helper(arguments))
		 * @param {String}          storage - Storage name; by default it will be the same name as the helper
		 * @return {Object|Function|null}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		defineHelper : function(object, helper, storage) {
			
			// Defines storage name
			storage = storage || helper;
			
			// Creates the jQuery helper function
			jQuery.fn[helper] = function() {
				
				// Variables
				var args = Array.prototype.slice.call(arguments), // Function arguments
					current, instance, constructor, result;
				
				// For each matched element
				for(var x = 0, max = this.length; x < max; x++) {
					
					// Current element
					current = jQuery(this[x]);
					
					// Try to get the instance
					instance = current.data(storage);
					
					// if methos is the keywork 'get', then it will try to get the instance
					if(args[0] === 'get') {
						
						// Retruns the object from the object storage or undefined
						return instance;
						
					}
					
					// if first argument is an object (options)
					else if(typeof instance === 'undefined') {
						
						// Creates the new instance intermetiate function (binded to the object prototype)
						constructor = object.bind(new object, jQuery.merge([current], args));
						
						// Initialized and stores the object
						current.data(storage, new constructor());
						
					}
					
					// if the methos belongs to the object
					else if(typeof instance[args[0]] === 'function') {
						
						// Applys the method
						result = instance[args[0]].apply(instance, args.slice(1));
						
						// If function returns something
						if(typeof result !== 'undefined') {
							
							// Returns the returned value
							return result;
							
						}
						
					}
					
				}
				
			};
			
		},
		
		/**
		 * Checks for an element click outside
		 * @param {Object|String} element  - Object or selector to check for the click outside
		 * @param {Function}      callback - Callback to execute
		 * @param {Object|String} related  - Set of related elements or selectors
		 * @return {Function}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		clickOutside : function(element, callback, related) {
			
			// Variables
			var handler = function(event) {
				
					// Variables
					var target = jQuery(event.target);
					
					// If event was triggered outside the specified element
					if(!target.closest(jQuery(element)).length && (typeof related === 'undefined' || !target.closest(jQuery(related)).length)) {
						
						// Executes the callback
						callback.apply(element, arguments);
						
					}
					
				},
				doc = jQuery(document);
			
			// If document doesnt have the event yet
			if(doc.hasEvent('click', handler) === false) {
				
				// Sets the document event
				doc.click(handler);
				
			}
			
			// Returns the handler, so it can be removed from document events
			return handler;
			
		},
		
		/**
		 * Check for defined events in a specified jQuery object
		 * @param {Object|String} element - Object or selector to check for events
		 * @param {String}        type    - Type of event
		 * @param {Function}      handler - Handler for the events
		 * @return {Boolean}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		hasEvent : function(element, type, handler) {
			
			// Variables
			var events = jQuery._data(jQuery(element)[0], 'events'); // Tricky :S
			
			// If there are no events attached to the element, or the event is undefind, if the type of event is not defined for the element, or the elements has no events of that type
			if(typeof events === 'undefined' || typeof type === 'undefined' || typeof events[type] === 'undefined' || events[type].length === 0) {
				
				// ): no events of that type
				return false;
				
			}
			
			// If there is no handler specified (only checks for the type of event)
			if(typeof handler === 'undefined') {
				
				// The element has that type of events defined
				return true;
				
			}
			
			// For each defined event
			for(var i = 0, max = events[type].length; i < max; i++) {
				
				// If the defined hanlder for the event is the same as the specified handler
				if(handler === events[type][i].handler) {
					
					// The element has the event
					return true;
					
				}
				
			}
			
			// ):
			return false;
			
		},
		
		/**
		 * Exposes an element to the DOM and measures it
		 * @param {String|Object} element  - Element ot expose an measure
		 * @param {Function}      callback - Function to execute ag a measure getter (must return an object {{width, height}}
		 * @return {{width, height}}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		measure : function(element, callback) {
			
			// Clones the first element
			var copy = jQuery(element).clone(),
				value;
			
			// Hides and inserts the clone in the document boty
			jQuery(document.body).append(copy.hide());
			
			// If the supplied callback is a function
			if(typeof callback === 'function') {
				
				// Binds the callback to the cloned element
				return callback.apply(copy);
				
			} else {
				
				// Measures the element
				value = { width : 0, height : 0 };
				value.width  = copy.outerWidth();
				value.height = copy.outerHeight();
				
			}
			
			// Removes the cloned element
			copy.remove();
			
			// (:
			return value;
			
		},
		
		/**
		 * Scrolls to a specific element
		 * @param {String|Object} selector - element selector or specified object
		 * @param {Number}        duration - animation duration - in milliseconds
		 * @param {Number}        offset   - scroll offset
		 * @param {Function}      callback - callback to execute after scroll ends
		 * @return {Object}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		scrollToElement : function(selector, duration, offset, callback) {
			
			// sets default values for duration, offset and callback
			duration = typeof duration !== 'undefined' ? duration : 1000;
			offset   = typeof offset   !== 'undefined' ? offset   : 0;
			callback = typeof callback === 'function'  ? callback : jQuery.empty
			
			// Variables
			var element  = jQuery(selector), // element to scroll to
				scrollto = element.offset().top + offset; // element position
			
			// Scrolls to element
			jQuery('html, body').animate({
				scrollTop : scrollto
			}, duration, callback);
			
			// Returns teference to object
			return element;
			
		},
		
		
		/**
		 * Scrolls and element to a specific position
		 * @param {String|Object} element  - scrollable element
		 * @param {Number}        position - x or y position to scroll to
		 * @param {String}        axis     - x or y
		 * @param {Number}        duration - animation duration - in milliseconds
		 * @param {Function}      callback - callback to execute after scroll ends
		 * @return {Object}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		scrollElementTo : function(element, position, axis, duration, callback) {
			
			// sets default values for duration, offset and callback
			duration = typeof duration !== 'undefined' ? duration : 1000;
			axis     = typeof axis     !== 'undefined' ? axis     : 'x';
			callback = typeof callback === 'function'  ? callback : jQuery.empty
			
			// Define the options
			var options = axis === 'x' ? { scrollLeft : position } : { scrollTop : position };
			
			// Scrolls to element
			return jQuery(element || 'html, body').animate(options, duration, callback);
			
		},
		
		/**
		 * Computes a value. Supplied can be a number or a function (will be executed binded to the current object).
		 * @method getOptionValue
		 * @param {Function|Number|Boolean} value - Value to parse (function will be exectued)
		 * @return {*}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		getOptionValue : function(value, object, args) {
			
			// If defined offset is a function
			if(typeof value === 'function') {
				
				// executes the function and returns the returned value
				return value.apply(object, args || []);
				
			}
			
			// If defined offset if numeric
			else {
				
				// (:
				return value;
				
			}
			
		}
		
	};
	
	
	// Defines helpers
	jQuery.fn.extend(jQuery.fn, {
		
		// Click outside the element
		clickOutside : function(callback, related) {
			
			// Returns the first element handler
			return CRD.Utils.clickOutside(this[0], callback, related);
			
		},
		
		// Check if an element has an event
		hasEvent : function(type, handler) {
			
			// Returns if the first element has an event of certain type, with the specified handler
			return CRD.Utils.hasEvent(this[0], type, handler);
			
		},
		
		// Mesures an element exposed to the DOM
		measure : function(callback) {
			
			// Returns the size of the  measured element
			return CRD.Utils.measure(this[0], callback);
			
		}
		
	});
	
})();