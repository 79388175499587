/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Executes the overriting functions
(function(Layout) {
	"use strict";
	
	// Variables
	var constants = {
			Direction : {
				VERTICAL   : 'vertical',
				HORIZONTAL : 'horizontal'
			}
		};
	
	/**
	 * Calculates the offset (top or left) for the stacked elements
	 * @param {Number} index     - Current element index
	 * @param {Object} childrens - Array of stacked elements
	 * @param {String} direction - Direction for the calcularions (Layout.Direction.VERTICAL or Layout.Direction.HORIZONTAL)
	 * @return {Number}
	 * @private
	 */
	
	function getOffset(index, childrens, direction) {
		
		// Variables
		var value = 0,
			i, max;
		
		// Depending on the search direction
		switch(direction) {
			
			// Horizontally (will get the right offset)
			case constants.Direction.HORIZONTAL:
				
				// to-do : implement horizontal direction
				
				break;
			
			// Vertical (will get the offset top)
			case constants.Direction.VERTICAL:
				
				// For each previous element
				for(i = 0, max = index; i < max; i++) {
					
					// Add the height value to the computed value
					value += childrens[i].options.height;
					
				}
				
				break;
			
		}
		
		// (:
		return value;
		
	}
	
	/**
	 * Extends default options for CRD.Layout
	 * to-do : implement horizontal and mixed stack
	 * @property {Object}  defaults                 - Default options
	 * @property {Boolean} defaults.stack           - Element is a stack container?
	 * @property {String}  defaults.direction       - Direction of the stack (Layout.Direction.VERTICAL or Layout.Direction.HORIZONTAL)
	 * @property {String}  defaults.stackedSelector - Selector for nested stacked elements
	 * @see CRD.Layout.prototype.defaults for complete list of options
	 */
	
	Layout.prototype.defaults = jQuery.extend(true, Layout.prototype.defaults, {
		stack           : false,
		direction       : constants.Direction.VERTICAL,
		stackedSelector : '[data-layout-stacked]'
	});
	
	/**
	 * Stores the initial set of options
	 * @property {Object} initial
	 */
	
	Layout.prototype.initial = null;
	
	// Stores the original resize function from CRD.Layout
	var resize = Layout.prototype.resize;
	
	/**
	 * Overrides original resize function to apply the stacking functions.
	 * @method resize
	 * @param {Boolean} main - Is this the main node of the resiize chain?
	 * @fires crd.layout.resize
	 * @override CRD.Layout.prototype.resize
	 * @memberOf Layout
	 * @public
	 */
	
	Layout.prototype.resize = function(main) {
		
		// Variables
		var direction = this.options.direction, // Direction of the stack
			auto = [], // Amount of elements with 'auto' height
			used = 0, // Used height (for height defined elements)
			innerSize = this.element.getInnerSize(), // Get the element inner size
			childrens, calculated, obj;
		
		// Calls the default resize function
		resize.apply(this, arguments);
		
		// If the elements is a stack element container and the resize isnt disabled
		if(this.options.stack === true && this.disabled === false) {
			
			// Depending on the stack direction
			switch(direction) {
				
				// Elements stacked horizontally
				case constants.Direction.HORIZONTAL:
					
					// to-do : implement horizontal direction
					
					break;
				
				// Elements stacked vertically
				case constants.Direction.VERTICAL:
					
					// Get all child elements
					childrens = this.element.children(this.options.stackedSelector);
					
					// For each child element gets all setted heights
					for(var i = 0, max = childrens.length; i < max; i++) {
						
						// Set the current object (check if Layout
						obj = jQuery(childrens[i]).data(Layout.Storage.MAIN);
						
						// If the object has been initialized yet
						if(typeof obj !== 'undefined') {
							
							// Clone (so it wont be referenced) the options from the stored (stored initial options) or object options
							childrens[i].options = jQuery.extend({}, obj.initial || obj.options);
							
						} else {
							
							// Get the options from the html atributed options
							childrens[i].options = Layout.getOptions(childrens[i]);
							
						}
						
						//console.log(typeof obj !== 'undefined' ? 'SI' : 'NO', childrens[i], childrens[i].options)
						
						// If the element donest have the height setted
						if(typeof childrens[i].options.height === 'undefined' || childrens[i].options.height === null) {
							
							// Set the child element that its height needs to be calculated
							auto.push(i);
							
						} else {
							
							// Sets the amount of used height
							used += childrens[i].options.height;
							
						}
						
					}
					
					// If some elements need their height calculared from available height
					if(auto.length > 0) {
						
						// Calculates the size for the elements with height 'auto'
						calculated = Math.max(0, (innerSize.height - used) / auto.length);
						
						// For each elements that needs their height calculated
						for(i = 0, max = auto.length; i < max; i++) {
							
							// Sets the calculated height
							childrens[auto[i]].options.height = calculated;
							
						}
						
					}
					
					// For each child element gets all setted heights
					for(i = 0, max = childrens.length; i < max; i++) {
						
						// Get the offset top for the element
						childrens[i].options.top = getOffset(i, childrens, direction);
						
					/*}
					
					for(i = 0, max = childrens.length; i < max; i++) {*/
						
						// Set the current object
						obj = jQuery(childrens[i]).data(Layout.Storage.MAIN);
						
						// If a Layout object is binded to the child element
						if(typeof obj !== 'undefined') {
							
							// If initial options hasnt been stored (the Layout instance was previously created)
							if(obj.hasOwnProperty('initial') === false) {
								
								// Stores the initial options (cloning so it wont be referenced)
								obj.initial = jQuery.extend({}, obj.options);
								
							}
							
							// Set the new options for the child element
							obj.setOptions(childrens[i].options);
							
						} else {
							
							// Initializes the object
							obj = new Layout(childrens[i], childrens[i].options);
							
							// Stores the initial options
							obj.initial = Layout.getOptions(childrens[i]) || {};
							
						}
						
					}
					
					break;
				
			}
			
			// Propagate the resize to child elements
			this.propagate();
			
		}
		
	};
	
	// Set object constants
	CRD.Utils.setConstants(Layout, constants);
	
})(CRD.Layout);