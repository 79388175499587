(function() {
	"use strict";
	
	jQuery.extend(Function.prototype, {
		
		/**
		 * Debouce method for functions
		 * @param {Number}  wait      - Timeout for debounce
		 * @param {Boolean} inmediate - Function must be executed right away?
		 * @returns {Function}
		 * @memberof Function.prototype
		 */
		
		debounce : function(wait, inmediate) {
			
			//  Variables
			var timeout, // Timeout pointer
				func = this; // Current function
			
			// Returns debounced function callback
			return function() {
				
				// Variables
				var context = this, // Self referenced object
					args    = arguments, // Function arguments
					later   = function() {
						timeout = null;
						if(!inmediate) {
							func.apply(context, args);
						}
					}, // Timeout function callback
					callnow = inmediate && !timeout; // Shall be executed?
				
				// Clears debounce timeout
				clearTimeout(timeout);
				
				// Regenerates timeout
				timeout = setTimeout(later, wait);
				
				// If the function muy be called
				if(callnow) {
					func.apply(context, args);
				}
				
			};
			
		},
		
		/**
		 * Delayed appliance for functions
		 * @param {Number} delay   - Delay in milliseconds
		 * @param {Object} context - Object, function or class to whic the function will be binded
		 * @param {Array}  args    - Arguments
		 * @return {Number}
		 * @memberof Function.prototype
		 */
		
		delay : function(delay, context, args) {
			
			// Variables
			var func = this; // Current function
			
			// Use supplied arguments (as an array), or defined
			args = args || Array.prototype.slice.call(arguments).slice(2);
			
			// Returns the function
			return setTimeout(function() {
					
				// Applies the function to the specified object or to the function itself
				func.apply(context, args.length > 0 ? args : Array.prototype.slice.call(arguments));
					
			}, delay);
			
		},
		
		/**
		 * Executes a function just once
		 * @param {Object|null} context - Context to which the function will be applyed
		 * @return {Function}
		 * @memberof Function.prototype
		 */
		
		once : function(context) {
			
			// Variables
			var func = this, // Current function
				result;
			
			// Returns the function
			return function() {
				
				// If function is set
				if(func) {
					
					// Executes the function
					result = func.apply(context || this, arguments);
					
					// Unset the function, so it will not be called again
					func = null;
					
				}
				
				// (:
				return result;
				
			};
			
		},
		
		/**
		 * Copy an object prototype to another object or contructor.
		 * Model by Stoyan Stefanov explaines in Javascript Patterns.
		 * @param {Object} object - Object from wich th prototype will be copyed.
		 * @memberof Function.prototype
		 */
		
		inherit : function(object) {
			
			// Variables
			var F = function() {}; // Creates the function that will inherits the object prototype
			
			// Assign the object prototype to the copying function
			F.prototype = object.prototype;
			
			// Assigns the function prototype with a new constructed function
			this.prototype = new F();
			
			// Sets the constructor reference
			this.prototype.constructor = this;
			
			// Sets a parent property to the function, so any prototype method can be called thru it
			this.prototype.parent = object.prototype;
			
		},
		
		/**
		 * Applies a method or function to the desired object or class.
		 * @param {Object} object - Object to apply the method.
		 * @param {Array}        args   - Argument for the applied method.
		 * @return {Function}
		 * @memberof Function.prototype
		 */
		
		bind : function(object, args) {
			
			// Variables
			var f = this; // Function to apply
			
			// Use supplied arguments (as an array), or defined
			args = args || Array.prototype.slice.call(arguments).slice(1);
			
			// Returns the function that applies 'this' function to the required object
			return function() {
				
				// If bind call get arguments, use those arguments, otherwise it will use the arguments passed when called
				return f.apply(object, args.length > 0 ? args : Array.prototype.slice.call(arguments));
				
			};
			
		}
		
	});
	
})();