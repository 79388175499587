/* jshint -W058 */

/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.ShortenText = (function(context) {
	"use strict";
	
	// Variables
	var constants = {
			Mode      : {
				VISIBLE : 'visible',
				HIDDEN  : 'hidden'
			},
			Events    : {
				SHOW : 'crd.shortentext.show',
				HIDE : 'crd.shortentext.hide'
			}
		}, // Constants
		storage = {
			main  : 'crd.shortentext',
			state : 'crd.shortentext.state',
			html  : {
				main  : 'shorten-text',
				state : 'shortened'
			}
		}; // Data and HTML storage
	
	/**
	 * Shorten text
	 * @class
	 * @param {String|Object} element - Text node (shortened text node)
	 * @param {Object}        options - Options to override default text shorten options.
	 * @return {ShortenText}
	 */
	
	function ShortenText(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data(storage.main); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			this.setOptions(options);
			
			/**
			 * Text container (should be a text node element)
			 * @property {Object} element - Text node element
			 */
			
			this.element = element;
			
			// Stores the object reference in the element storage
			this.element.data(storage.main, this);
			
			// Initializes the object
			this.ShortenText();
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	// ShortenText prototype implements ClassUtils
	
	ShortenText.prototype = jQuery.extend({
		
		/**
		 * Default options
		 * @property {Object}   defaults              - Default options
		 * @property {String}   defaults.hideafter    - Hide after that number of characters
		 */
		
		defaults : {
			hideafter    : 150,
			overflow     : '... ',
			allowshorten : true,
			constructors : {
				toggler  : '<a href="javascript:;">{label}</a>'
			},
			language     : {
				more : 'Show more',
				less : 'Show less'
			}
		},
		
		/**
		 * Initializes the text shortener
		 * @constructor
		 * @memberof ShortenText
		 * @public
		 */
		
		ShortenText : function() {
			
			// If the text is longer than the defined maximum characters
			if(this.element.text().length > this.options.hideafter) {
				
				// Initializes the text shortener
				this.create();
				
			}
			
		},
		
		/**
		 * Creates the "show more" toggler and hooks all events
		 * @method create
		 * @return {ShortenText}
		 * @memberOf ShortenText
		 * @public
		 */
		
		create : function() {
			
			// Variables
			var text = this.element.text(), // Complet text
				excertp = text.substring(0, this.options.hideafter), // Text excerpt
				longtext = text.substring(this.options.hideafter), // Remaining text
				overflow = jQuery('<span />'), // Text overflow container
				container = jQuery('<span />'), // Remaining text container
				toggler = this.createToggler(constants.Mode.HIDDEN); // Creates the toggler
			
			// Stores the initial state (hidden)
			this.element.data(storage.state, constants.Mode.HIDDEN);
			
			// Sets the overlor options
			overflow.text(this.options.overflow);
			
			// Sets remaining text container options
			container.text(longtext).hide();
			
			// Replaces the element html with the excerpt, the remaining text and the toggler
			this.element.text(excertp)
				.append(container, overflow, toggler);
			
			// Sets the overflow and text element references
			this.overflow = overflow;
			this.fulltext = container;
			
			// (:
			return this;
			
		},
		
		/**
		 * Creates the toggler with the corresponding text
		 * @method createToggler
		 * @param {String} mode - Mode (show/hide)
		 * @memberof ShortenText
		 * @return {Object}
		 * @public
		 */
		
		createToggler : function(mode) {
			
			// Variables
			var toggler = jQuery(this.options.constructors.toggler.substitute({ label : this.options.language[mode === constants.Mode.VISIBLE ? 'less' : 'more'] }));
			
			// If the click handler is not defined
			if(typeof this.clickHandler === 'undefined') {
				
				// Creates the click handler for the toggler
				this.clickHandler = function(e) {
					e.preventDefault();
					this.toggle();
				}.bind(this);
				
			}
			
			// Adds the triggers event
			toggler.click(this.clickHandler);
			
			// If the toggler is defined
			if(typeof this.toggler !== 'undefined') {
				
				// Replaces the current toggler with the new one
				this.toggler.replaceWith(toggler);
				
			}
			
			// Sets the toggler refernce
			this.toggler = toggler;
			
			 // Returns the toggler element
			return toggler;
			
		},
		
		/**
		 * Toggles the text
		 * @method toggle
		 * @return {ShortenText}
		 * @memberOf ShortenText
		 * @public
		 */
		
		toggle : function() {
			
			// Variables
			var state = this.element.data(storage.state);
			
			// Shows or hide the content
			this[state === constants.Mode.VISIBLE ? 'hide' : 'show']();
			
			// (:
			return this;
			
		},
		
		/**
		 *
		 * @method show
		 * @fires CRD.ShortenText.Events.SHOW
		 * @return {ShortenText}
		 * @memberOf ShortenText
		 * @public
		 */
		
		show : function() {
			
			// Sets the new state
			this.element.data(storage.state, constants.Mode.VISIBLE);
			
			// If the text can be shorten back
			if(this.options.allowshorten === true) {
				
				// Replaces the toggler
				this.createToggler(constants.Mode.VISIBLE);
				
			} else {
				
				// Removes the toggler
				this.toggler.remove();
				
			}
			
			// Hides the overflow and shows the entire text
			this.overflow.hide();
			this.fulltext.show();
			
			/**
			 * Triggers the show event
			 * @event crd.shortentext.show
			 */
			
			this.dispatch(constants.Events.SHOW, [
				this.element,
				this
			]);
			
			// (:
			return this;
			
		},
		
		/**
		 *
		 * @method hide
		 * @fires CRD.ShortenText.Events.HIDE
		 * @return {ShortenText}
		 * @memberOf ShortenText
		 * @public
		 */
		
		hide : function() {
			
			// Sets the new state
			this.element.data(storage.state, constants.Mode.HIDDEN);
			
			// Replaces the toggler
			this.createToggler(constants.Mode.HIDDEN);
			
			// Shows the overflow and hide the entire text
			this.overflow.show();
			this.fulltext.hide();
			
			/**
			 * Triggers the close event
			 * @event crd.shortentext.hide
			 */
			
			this.dispatch(constants.Events.HIDE, [
				this.element,
				this
			]);
			
			// (:
			return this;
			
		}
		
	}, CRD.ClassUtils);
	
	// Set object constants
	CRD.Utils.setConstants(ShortenText, constants);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(ShortenText, 'shorten', storage.main);
	
	// Return the object reference
	return ShortenText;
	
})();