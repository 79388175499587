/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

(function() {
	"use strict";
	
	// Utilities namespace
	CRD.ClassUtils = {
		
		/**
		 * Sets the options (in a property called options) for the current class. Inherts the default options (stored
		 * in a property defaults).
		 * @method setOptions
		 * @param {Object} options - Options to override the default options.
		 * @return {CRD.ClassUtils}
		 * @memberof CRD.ClassUtils
		 * @public
		 */
		
		setOptions : function(options) {
			
			// Variables
			var events;
			
			// If defined options have an 'on' property (reserved for events)
			if(typeof options === 'object' && options.hasOwnProperty('on') && typeof options.on === 'object') {
				
				// Store events in a variable
				events = options.on;
				
				// Removes reserved property from options
				delete options.on;
				
				// For each defined event
				for(var i in events) {
					
					// Filter unwanted properties and check for event to trigger only a function
					if(events.hasOwnProperty(i) && typeof events[i] === 'function') {
						
						// Set the defined event and binds the event to the current object or class
						jQuery(this).on(i, events[i].bind(this));
						
					}
					
				}
				
			}
			
			// Set object or class options; it will use any prior object options (if defined), default or empty object
			this.options = jQuery.extend(true, {}, this.options || this.defaults || {}, options);
			
			// (:
			return this;
			
		},
		
		/**
		 * Log messages to the console. Setting the log option (in the class options) as false, will disable all output
		 * messages for the current class.
		 * @method log
		 * @param {String} message - Message to log to the console
		 * @return {CRD.ClassUtils}
		 * @memberof CRD.ClassUtils
		 * @public
		 */
		
		log : function(message) {
			
			// Variables
			var log = this.hasOwnProperty('options') && this.options.hasOwnProperty('log'); // Does the current class options has a log property?
			
			// If a log property is defined for the class opions, and the value is true, or the log property is not defined at all
			if((log === true && this.options.log === true) || log === false) {
				
				// Log the message to the consile
				console.log.apply(console, arguments);
				
			}
			
			// (:
			return this;
			
		},
		
		/**
		 * Fires an event in a specified context
		 * to-do : convert to vanilla javascript an quit using jQuery events
		 * @method dispatch
		 * @param {String|Object} event   - Event namespace or object containing multiple events
		 * @param {Object}        args    - Array to pass as event arguments
		 * @param {Object}        context - Context to which the event will be bindes
		 * @return {CRD.ClassUtils}
		 * @memberof CRD.ClassUtils
		 * @public
		 */
		
		dispatch : function(event, args, context) {
			
			// Variables
			var events = typeof event === 'object' ?
				event :
				[{
					event   : event,
					args    : args,
					context : context
				}];
			
			// For each defined event
			for(var x = 0, max = events.length; x < max; x++) {
				
				// Filter unwanted properties
				if(events.hasOwnProperty(x)) {
					
					// Fires the event
					jQuery(events[x].context || context || this)
						.trigger(events[x].event, events[x].args || args || []);
					
				}
				
			}
			
			// (:
			return this;
			
		}
		
	};
	
})();