if(typeof CRD === 'undefined') {
	var CRD = {};
}

/**
 * BreakpointSpy
 * Hooks to window resize, to query thru a set of user defines CSS queries, to set named breakpoints. Initially build to
 * match CSS queries against Bootstrap default breakpoints rules.
 * @class CRD.BreakpointSpy
 * @param {Object} options - Options to override default breakpoint spy options.
 */

CRD.BreakpointSpy = function(options) {
	"use strict";
	
	// Set options and events
	CRD.Utils.setOptions(this, options, this.defaults);
	
	/**
	 * Matched breakpoint information
	 * @property {Object}  breakpoint         - Current breakpint information
	 * @property {string}  breakpoint.current - Current breakpoint name
	 * @property {Boolean} breakpoint.hdpi    - Hight DPI screen? (retina)
	 * @property {Array}   breakpoint.matched - Current breakpoint stack (all matched rules)
	 * @defaultvalue
	 */
	
	this.breakpoint = {
		current : 'default',
		hdpi    : false,
		matched : []
	};
	
	/**
	 * Set of defined rules
	 * @property {Object} rules - Set of defined rules. Pairs of object properties and values, where properties matched
	 *                            the name of the rule to be assigned (if matched) and value defines the CSS query to
	 *                            match against. If there are any rules defined in the object prototype, the spy will
	 *                            also use the prototype inherited rules. The spy will assume that if there are any
	 *                            rules in the prototype, is on prupose, so, the object will inherit those rules.
	 * @defaultvalue
	 */
	
	this.rules = CRD.BreakpointSpy.prototype.hasOwnProperty('rules') ?
		jQuery.extend({}, CRD.BreakpointSpy.prototype.rules) :
		{};
	
	// Initializes the objet
	this.BreakpointSpy();
	
};

CRD.BreakpointSpy.prototype = (function() {
	"use strict";
	
	// Variables that wont change at runtime
	var msielet9   = navigator.userAgent.match(/msie (8|9)/i), // IE <= 9
		matchmedia = typeof window.matchMedia !== 'function' || msielet9 ? false : window.matchMedia, // Match media function
		isHDPI     = matchmedia !== false ? matchmedia('(-webkit-min-device-pixel-ratio : 1.5) and (-o-min-device-pixel-ratio: 3/2) and (min--moz-device-pixel-ratio: 1.5) and (min-device-pixel-ratio: 1.5)').matches : false; // HDPI screens
	
	// Returns object prototype
	return {
		
		/**
		 * Default options
		 * @property {string}  defaults.rules - BreakpointSpy rules
		 */
		
		defaults : {
			rules : []
		},
		
		/**
		 * Initializes BreakpontSpy object
		 * @constructor
		 * @listen window.domready
		 * @listen window.resize
		 * @memberof CRD.BreakpointSpy
		 * @public
		 */
		
		BreakpointSpy : function() {
			
			// Variables
			var rules = this.options.rules; // Current rules
			
			// If there are any supplied rukes
			if(rules !== null && typeof rules === 'object' && rules.length > 0) {
				
				// Adds all defined rules
				this.addAllThese(rules);
				
			}
			
			// Sets event handler function
			this.setHandler = function() {
				this.setBreakpoint();
			}.bind(this);
			
			// Hooks to resize event
			jQuery(window)
				.resize(this.setHandler);
			
			// Sets breakpoint inmediately
			this.setBreakpoint();
			
		},
		
		/**
		 * Sets current breakpoint
		 * @method setBreakpoint
		 * @fires crd.breakpointspy.default
		 * @fires crd.breakpointspy.change
		 * @return {CRD.BreakpointSpy}
		 * @memberof BreakpointSpy
		 * @public
		 */
		
		setBreakpoint : function() {
			
			// Variables
			var self = this, // Self reference object
				i, // Rule iterator
				match   = false, // Match?
				rule, // Rule reference
				last, // Last matched breakpoint
				changed = false; // Breakpoint has changed since last time function was executed?
			
			// If matchMedia is not a defined function or IE < 10 (function is present bt with limited functionality)
			if(matchmedia === false) {
				
				// Unset main events
				this.destroy();
				
				// Sets breakpoint as 'default'
				this.breakpoint.current = 'default';
				
				// For each element to trigger the event into
				jQuery([this, document]).each(function(i, on) {
					
					/**
					 * Triggers event
					 * @event crd.breakpointspy.default
					 */
					
					jQuery(on)
						.trigger('crd.breakpointspy.default', [
							self
						]);
					
				});
				
			}
			
			// If matchedia is defines and fully functional
			else {
				
				// If HPDI has not been detected yet
				if(this.breakpoint.hdpi === null) {
					
					// Detects HDPI screens
					this.breakpoint.hdpi = this.isHDPI();
					
				}
				
				// Resets matched breakpoints stack
				this.breakpoint.matched = [];
				
				// For each media query registered as a rule
				for(i in this.rules) {
					
					// If the rule belongs to this object and current rule is not the default one (Ignores defualt rule)
					if(this.rules.hasOwnProperty(i) && i !== 'default') {
						
						// Gets rule definition
						rule = this.getRule(i);
						
						// Executes media query
						match = matchmedia(rule);
						
						// If media query matches
						if(match.matches === true) {
							
							// Sets las breakpoint matched
							last = i;
							
							// Add matched rule to matched breakpoints stack
							this.breakpoint.matched.push(last);
							
						}
						
					}
					
				}
				
				// If new breakpoint differs from last breakpoint
				if(last !== this.breakpoint.current) {
					
					// Set breakpoint change (since las time)
					changed = true;
					
					// Sets last matched breakpoint as current breakpoint
					this.breakpoint.current = last;
					
					// For each element to trigger the event into
					jQuery([this, document]).each(function(i, on) {
						
						/**
						 * Triggers events
						 * @event crd.breakpointspy.change
						 */
						
						jQuery(on)
							.trigger('crd.breakpointspy.change', [
								self.breakpoint.current,
								self.breakpoint.hdpi,
								self
							]);
						
					});
					
					// For each element to trigger the event into
					jQuery([this, document]).each(function(i, on) {
						
						/**
						 * Triggers events
						 * @event crd.breakpointspy.{lastbreakpointname}
						 */
						
						jQuery(on)
							.trigger('crd.breakpointspy.' + last, [
								self.breakpoint.hdpi,
								self
							]);
						
					});
					
				}
				
				// For each element to trigger the event into
				jQuery([this, document]).each(function(i, on) {
					
					/**
					 * Triggers events
					 * @event crd.breakpointspy.always
					 */
					
					jQuery(on)
						.trigger('crd.breakpointspy.always', [
							changed,
							self.breakpoint.current,
							self.breakpoint.hdpi,
							self
						]);
					
				});
				
			}
			
			// Returns reference to the object
			return this;
			
		},
		
		/**
		 * Adds a custom rule to the spy
		 * @method addRUle
		 * @param {String} name - Name of the rule to add
		 * @param {String} rule - CSS query
		 * @return {CRD.BreakpointSpy}
		 * @memberof CRD.BreakpointSpy
		 * @public
		 */
		
		addRule : function(name, rule) {
			
			// Variables
			var scope = this instanceof CRD.BreakpointSpy ? // Target is this or function prototype?
				this :
				CRD.BreakpointSpy.prototype;
			
			// If rules are not defined
			scope.rules = scope.hasOwnProperty('rules') ?
				this.rules :
			{};
			
			// Adds rule to defined rules
			scope.rules[name] = rule;
			
			// Returns reference to the object
			return scope;
			
		},
		
		/**
		 * Adds custom rules (batch mode)
		 * @method addAllTher=se
		 * @param {Array} rules - Rules to add
		 * @return {CRD.BreakpointSpy}
		 * @memberof CRD.BreakpointSpy
		 * @public
		 */
		
		addAllThese : function(rules) {
			
			// Variables
			var scope = this instanceof CRD.BreakpointSpy ? // Target is this or function prototype?
				this :
				CRD.BreakpointSpy.prototype;
			
			// For each defined rule
			for(var x = 0, max = rules.length; x < max; x++) {
				
				// Adds the rule
				scope.addRule(rules[x][0], rules[x][1]);
				
			}
			
			// Returns reference to the object
			return scope;
			
		},
		
		/**
		 * Clear all rules for the instance
		 * @method clearAllRUles
		 * @return {CRD.BreakpointSpy}
		 * @memberof CRD.BreakpointSpy
		 * @public
		 */
		
		clearAllRules : function() {
			
			// Variables
			var scope = this instanceof CRD.BreakpointSpy ? // Target is this or function prototype?
				this :
				CRD.BreakpointSpy.prototype;
			
			// Resets all rules
			scope.rules = {};
			
			// Returns reference to the object
			return scope;
			
		},
		
		/**
		 * Gets rules by rule name
		 * @method getRule
		 * @param {String} name - Name of the rule to get
		 * @returns {String|Boolean}
		 * @memberof CRD.BreakpointSpy
		 * @public
		 */
		
		getRule : function(name) {
			
			// Returns rule or false
			return this.rules.hasOwnProperty(name) ? this.rules[name] : false;
			
		},
		
		/**
		 * Gets the default rule for a given rule set
		 * @method getDefault
		 * @param {Array} data - Set of rules to use as haystack
		 * @returns {String|Boolean}
		 * @memberof CRD.BreakpointSpy
		 * @public
		 */
		
		getDefault : function(data) {
			
			// Returns default rule  or false
			return data.hasOwnProperty('default') ? data['default'] : false;
			
		},
		
		/**
		 * Gets the closest breakpoint for a given rule set
		 * @method getCloser
		 * @param {Array} data - Set of rules to use as haystack
		 * @returns {Object}
		 * @memberof CRD.BreakpointSpy
		 * @public
		 */
		
		getCloser : function(data) {
			
			// Variables
			var breakpointdata = {
					breakpoint : '',
					src        : false
				}, // Breakpoint data
				breakpoint     = this.breakpoint,
				current; // Current breakpoint
			
			// For eqach matched breakpoint in the current matched breakpoint stack
			for(var i in breakpoint.matched) {
				
				// Filter unwanter properties
				if(breakpoint.matched.hasOwnProperty(i)) {
					
					// Sets breakpoint
					current = breakpoint.matched[i];
					
					// If breakpoint exists in the given rule set and is not its default rule
					if(i !== 'default' && data.hasOwnProperty(current) && typeof data[current] !== 'undefined') {
						
						// Sets the breakpint as the closest breakpoint
						breakpointdata = {
							breakpoint : current,
							src        : data[current]
						};
						
					}
					
				}
				
			}
			
			// Returns closest breakpoint or false
			return breakpointdata;
			
		},
		
		/**
		 * Detects HDPI screens using CSS media queries
		 * @methos isHDPI
		 * @returns {Boolean}
		 * @memberof CRD.BreakpointSpy
		 * @public
		 */
		
		isHDPI : function() {
			
			// (:
			return isHDPI;
			
		},
		
		/**
		 * Destroys main events
		 * @method destroy
		 * @memberof BreakpointSpy
		 * @public
		 */
		
		destroy : function() {
			
			// Removes dom ready and window resize event
			jQuery(document)
				.off('ready', this.setHandler);
			jQuery(window)
				.off('resize', this.setHandler);
			
		}
		
	};
	
})();