(function() {
	"use strict";
	
	jQuery.extend(String.prototype, {
		
		/**
		 * Replacements in string (like Mootools substitute)
		 * @param {object} params - Replacements
		 * @return {String}
		 * @memberof String.prototype
		 */
		
		substitute : function(params) {
			
			// Return the string
			return String(this).replace(/{(.+?)}/g, function(string, match) {
				return match in params ? params[match] : string;
			});
			
		}
		
	});
	
})();