jQuery.extend(jQuery.fn, {

    /**
     * Check if an element is visible thru the viewport
     * @param {boolean} partial - Partially or completely visible
     * @returns {Boolean}
     * @memberof jQuery.fn
     */

    visible : function(partial) {

        //  Variables
        var self = jQuery(this), // Self
            viewport = jQuery(window), // Viewport (window opbject)
            offsetTop = viewport.scrollTop(),
            offsetBottom = offsetTop + viewport.height(),
            top = self.offset().top,
            bottom = top + self.height(),
            compareTop = partial === true ? bottom : top,
            compareBottom = partial === true ? top : bottom;

        // (:
        return (compareBottom <= offsetBottom) && (compareTop >= offsetTop) ? true : false;

    }


});