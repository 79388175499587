/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Executes the overriting functions
(function(Interchange) {
	"use strict";
	
	// Modifies the default selector
	Interchange.prototype.defaults.selector = '.interchange';
	
	/**
	 * Gathers sources data from element
	 * @method gatherData
	 * @param {Object} element - Element to gather data for
	 * @returns {Object}
	 * @memberof Interchange
	 * @override
	 */
	
	Interchange.prototype.gatherData = function(element) {
		
		// Element
		element = jQuery(element);
		
		// Variables
		var dataprefix    = 'image-', // Data attribute prefix
			normalization = {
				'xs' : 'default',
				'sm' : 'mobile',
				'md' : 'tablet',
				'lg' : 'desktop',
				'xl' : 'desktop-large'
			}, // Data normalization
			data          = {}, // Gathered data
			value; // Value for gathered data
		
		// For each breakpoint
		for(var i in normalization) {
			
			// Filter unwanter properties
			if(normalization.hasOwnProperty(i)) {
				
				// Get the data value
				value = element.data(dataprefix + normalization[i]);
				
				// If data exists
				if(typeof value !== 'undefined') {
					
					// Sets image source for breakpoint
					data[i] = value;
					
				}
				
			}
			
		}
		
		// Returns data object
		return data;
		
	};
	
})(CRD.Interchange);