(function($, scope) {
	"use strict";
	
	// Variables
	var spy, actions, tooltips, modifiables;
	
	// Initializes the breakpoint actions
	actions = new CRD.BreakpointActions({
		actions : [
			
			/**
			 * Changes the body font size according to viewport
			 * @param {boolean} changed
			 * @param {String}  breakpoint
			 * @param {boolean} hdpi
			 */
			
			function(changed, breakpoint, hdpi) {
				
				// Variables
				var width = Math.min(1440, $('body').width()), // max page width
					coef; // multiply coef
				
				// Switch between breakpoints and defines multiply coef
				switch(breakpoint) {
					case 'md':
						coef = 0.015;
						break;
					case 'lg':
						coef = 0.013;
						break;
					case 'xl':
						coef = 0.0115;
						break;
					default:
						coef = 0.04;
						break;
				}
				
				// Sets body font size
				$('body')
					.css('font-size', (width * coef) + 'px');
			
			},
			
			/**
			 * Sticky bar / mobile menu management
			 * @param {boolean} changed
			 * @param {String}  breakpoint
			 * @param {boolean} hdpi
			 */
			
			function(changed, breakpoint, hdpi) {
				
				// Variables
				var fixed, nav,
					home = jQuery(document.body).hasClass('home'),
					search = jQuery(document.body).hasClass('search');
				
				// If breakpoint has changed
				if(changed === true) {
					
					// Fixed bar
					fixed = $('#sticky-nav');
					
					// Switch between different breakpoints
					switch(breakpoint) {
						
						// Mobile and tablet
						case 'xs':
						case 'sm':
						case 'md':
							
							// Unloads the sticky desktop bar if its initialized
							if(typeof fixed.fixednav('get') !== 'undefined') {
								fixed.fixednav('destroy');
							}
							
							// If the mobile nav is not initialized
							if(typeof fixed.mobilenav('get') === 'undefined') {
								
								// Initializes the mobile nav
								nav = new CRD.MobileNav(fixed, '#sticky-nav .trigger div', '#mobile-menu', {
									initState  : home === true ? CRD.FixedNav.Mode.CLOSE : CRD.FixedNav.Mode.OPEN,
									positions  : {
										closed : function() {
											var e = jQuery('#sticky-nav');
											return -e.height();
										}
									},
									offset     : {
										// If on homepage, header area wont be an active area to display the fixed nav
										top : function() {
											return home === true ? jQuery('.header').height() : 0;
										}
									},
									conditions : [
										// Force to scroll at least the height of the sticky for the nav to hide
										function(direction, scrollY) {
											return direction === 'up' || (direction === 'down' && scrollY > jQuery('#sticky-nav')
												.outerHeight());
										}
									]
								});
								
								// Open/close icon animation classes
								$(nav).on({
									'crd.mobilenav.open'  : function(e, trigger) {
										$(trigger).addClass('open');
									},
									'crd.mobilenav.close' : function(e, trigger) {
										$(trigger).removeClass('open');
									}
								});
							}
							break;
							
						// Desktop
						default:
							
							// Unloads the mobile nav bar if its initialized
							if(typeof fixed.mobilenav('get') !== 'undefined') {
								fixed.mobilenav('destroy');
							}
							
							// If the sticky desktop bar is not initialized
							if(typeof fixed.fixednav('get') === 'undefined') {
								
								// Initializes the sticky desktop bar
								nav = new CRD.FixedNav('#sticky-nav', {
									initState    : home === true ? CRD.FixedNav.Mode.CLOSE : CRD.FixedNav.Mode.OPEN,
									hideOnScroll : !search,
									positions    : {
										closed : function() {
											var e = jQuery('#sticky-nav');
											return -e.height();
										}
									},
									offset       : {
										// If on homepage, header area wont be an active area to display the fixed nav
										top : function() {
											return home === true ? jQuery('.header').height() : 0;
										}
									},
									conditions   : [
										// Force to scroll at least the height of the sticky for the nav to hide
										function(direction, scrollY) {
											return direction === 'up' || (direction === 'down' && scrollY > jQuery('#sticky-nav')
												.outerHeight());
										}
									]
								});
								
								// Close all sticky dropdowns if the sticky bar hides
								$(nav).on('crd.fixednav.close', function() {
									$('.selector, .lang').each(function(i, e) {
										$(e).dropdown('close', 0);
									});
								});
								
							}
							break;
							
					}
					
				}
				
			},
			
			/**
			 * Sticky subnav (desktop and tablet only)
			 * @param {boolean} changed
			 * @param {String}  breakpoint
			 * @param {boolean} hdpi
			 */
			
			function(changed, breakpoint, hdpi) {
				
				// Variables
				var mainnav = $('#sticky-nav'),
					offset = jQuery(jQuery('#sticky-subnav').data('sticky-offset')),
					sticky;
				
				// Initializes the sticky
				sticky = new CRD.FixedNav('#sticky-subnav', {
					initState  : CRD.FixedNav.Mode.CLOSE,
					positions  : {
						closed : 0,
						opened : function() {
							return mainnav.height();
						}
					},
					offset     : {
						top : function(direction) {
							return offset.length > 0 ? offset.offset().top + (direction === 'down' ? 0 : -mainnav.outerHeight()) : 0;
						}
					},
					conditions : [
						function(direction, scrollY) {
							return direction === 'up' || (direction === 'down' && scrollY > mainnav
								.outerHeight());
						}
					]
				});
				
				// Show/hides sticky when active or inactive
				$(sticky).on({
					'crd.fixednav.active'   : function(e, fixed) {
						$(fixed).css({
							'display'    : 'block',
							'visibility' : 'visible'
						});
					},
					'crd.fixednav.inactive' : function(e, fixed) {
						$(fixed).css({
							'display'    : 'none',
							'visibility' : 'hidden'
						});
					}
				});
				
			},
			
			/**
			 * Main splash height
			 * @param {boolean} changed
			 * @param {String}  breakpoint
			 * @param {boolean} hdpi
			 */
			
			function(changed, breakpoint, hdpi) {
				
				// Variables
				var element = $('.header'),
					home = jQuery(document.body).hasClass('home'),
					minheight = 640,
					height = 'auto';
				
				// Not in homepage
				if(home === false || element.length === 0) return;
				
				// Switch between different breakpoints
				switch(breakpoint) {
					
					// Desktop
					case 'lg':
					case 'xl':
						
						// Sets the height to Height of the viewport
						height = Math.max(minheight, jQuery(window).innerHeight());
						
						// Adds resiized class
						element.addClass('resized');
						
						break;
						
					default:
						
						// Removes resiized class
						element.removeClass('resized');
						
						break;
					
				}
				
				// Sets the height
				element.css('height', height);
				
			},
			
			/**
			 * Content offset
			 * @param {boolean} changed
			 * @param {String}  breakpoint
			 * @param {boolean} hdpi
			 */
			
			function(changed, breakpoint, hdpi) {
				
				// Variables
				var home = jQuery(document.body).hasClass('home'),
					element;
				
				// Skip if home
				if(home === true) return;
				
				// Get the first element
				element = $($('body > .container-fluid')[0]);
				
				// Sets the first element offset
				element.css('margin-top', $('#sticky-nav').height());
				
			},
			
			/**
			 * Tooltips enable/disable
			 * @param {boolean} changed
			 * @param {String}  breakpoint
			 * @param {boolean} hdpi
			 */
			
			function(changed, breakpoint, hdpi) {
				
				// If the breakpoint has changed
				if(changed === true) {
					
					// Switch between different breakpoints
					switch(breakpoint) {
						
						// Desktop
						case 'xl':
						case 'lg':
							
							// If tooltips have not been initialized
							if(typeof tooltips === 'undefined') {
								
								// Tooltips
								tooltips = new CRD.Tooltips();
								
							}
							
							break;
							
						// Mobile and tablet
						default:
							
							// If tooltips are enabled
							if(typeof tooltips !== 'undefined') {
								
								// Disable tooltips
								tooltips.destroy();
								
							}
							
							break;
						
					}
					
				}
				
			}
			
		]
	});
	
	// Initializes the modifiables
	modifiables = new CRD.BreakpointDOMActions();
	
	// Initializes the breakpoint spy
	spy = new CRD.Interchange();
	
	// Dropdowns
	$('.selector, .lang').dropdown();
	
	// Expandables
	jQuery('ul.list[data-expandable]').expandable({
		hideafter    : 5,
		constructors : {
			toggler : '<a href="" class="show-more">{label} <i></i></a>'
		},
		language     : {
			more : 'Ver más',
			less : 'Ver menos'
		},
		on           : {
			'crd.expandable.show' : function(e, list, items, toggler) {
				toggler.addClass('less');
			},
			'crd.expandable.hide' : function(e, list, items, toggler) {
				toggler.removeClass('less');
			}
		}
	});
	
	// Text shorteners
	jQuery('p[data-shorten-text]').shorten({
		allowshorten : false,
		constructors : {
			toggler : '<a href="javascript:;" class="read-more">{label} <i></i></a>'
		},
		language     : {
			more : 'Leer más',
			less : 'Menos'
		}
	});
	
	// Smooth links
	jQuery(document.body).on('click', 'a[data-smooth-scroll]', function(event) {
		var clicked = event.target.tagName === 'A' ? jQuery(event.target) : jQuery(event.target).closest('a'),
			related = jQuery(clicked.attr('href')),
			offset = related.offset().top < clicked.offset().top ?
				- (jQuery('#sticky-nav').height() + jQuery('#sticky-subnav').height() + 25) :
				- (jQuery('#sticky-subnav').height() + 25);
		event.preventDefault();
		CRD.Utils.scrollToElement(related, 1000, offset);
	});
	
	// Normalizes mode
	scope.mode = typeof scope.mode !== 'undefined' ? scope.mode :  '';
	
	// Modes
	switch(scope.mode) {
		
		/**
		 * Homepage
		 */
		
		case 'home':
			
			// Highlighted slider
			$('.highlighted .list').slick({
				infinite     : false,
				arrows       : true,
				dots         : false,
				slidesToShow : 4,
				responsive   : [
					{
						breakpoint : 991,
						settings   : {
							slidesToShow : 3,
							arrows       : true,
							dots         : false
						}
					},
					{
						breakpoint : 767,
						settings   : {
							slidesToShow : 1,
							arrows       : false,
							dots         : true
						}
					}
				]
			});
			
			// Blog slider
			$('.blog .blog-list').slick({
				infinite     : false,
				arrows       : true,
				dots         : false,
				slidesToShow : 2,
				responsive   : [
					{
						breakpoint : 991,
						settings   : {
							slidesToShow : 1,
							arrows       : true,
							dots         : false
						}
					},
					{
						breakpoint : 767,
						settings   : {
							slidesToShow : 1,
							arrows       : false,
							dots         : true
						}
					}
				]
			});
			
			// Equal height slides
			$('.blog .blog-list').on('setPosition', function() {
				var track, height;
				$(this).find('.slick-slide').height('auto');
				track = $(this).find('.slick-track');
				height = $(track).height();
				$(this).find('.slick-slide').css('height', height + 'px');
			});
			
			// Ebooks slider
			$('.ebooks .list').slick({
				infinite     : false,
				arrows       : false,
				dots         : false,
				slidesToShow : 1,
				responsive   : [
					{
						breakpoint : 9999, // infinite?
						settings   : "unslick"
					},
					{
						breakpoint : 767,
						settings   : {
							dots : true
						}
					}
				]
			});
			
			// Equal height slides
			$('.ebooks .list').on('setPosition', function() {
				var track, height;
				$(this).find('.slick-slide').height('auto');
				track = $(this).find('.slick-track');
				height = $(track).height();
				$(this).find('.slick-slide').css('height', height + 'px');
			});
			
			// Register slider
			$('.register .list').slick({
				infinite     : false,
				arrows       : false,
				dots         : false,
				slidesToShow : 1,
				responsive   : [
					{
						breakpoint : 9999, // infinite?
						settings   : "unslick"
					},
					{
						breakpoint : 767,
						settings   : {
							dots : true
						}
					}
				]
			});
			
			break;
		
		/**
		 * Search
		 */
			
		case 'search':
			
			// Variables
			var layout = $('div.search-container');
			
			// Defines the layout status
			scope.layoutready = false;
			
			// Initializes layout
			layout.layout({
				disabledBefore : 0,
				maxWidth       : 1440
			});
			
			// Hook to layout to check when the layout changes
			layout.on(CRD.Layout.Events.RESIZE, function() {
				scope.layoutready = true;
			}.once());
			
			// Hook to layout disable
			layout.on(CRD.Layout.Events.DISABLE, function() {
				scope.layoutready = true;
			}.once());
			
			// Filter toggler
			$('.results-info a.filter').click(function() {
				$(this).toggleClass('open');
				$('.filter-results').toggleClass('show');
			});
			
			// Cancel button
			$('a.button.cancel').click(function() {
				$('.results-info a.filter').trigger('click');
			});
			
			// Filters dropdowns
			$('.filter-results .dropdown').dropdown({
				triggers   : '.label',
				containers : 'ul',
				native     : true,
				options    : 'li'
			});
			
			// Results slick lists
			(function() {
				
				/**
				 * Update dots
				 * @param {Object} container - List result item container
				 * @param {Number} index     - Selected index
				 */
				
				function updateDots(container, index) {
					
					// Variables
					var buttons = container.find('.dot');
					
					// Adds and remove activation classes
					buttons.removeClass('current');
					$(buttons[index]).addClass('current');
					
				}
				
				// Breakpoint change hook
				actions
					.add(
						
						/**
						 * Changes paging containers width
						 * @param {boolean} changed
						 * @param {String}  breakpoint
						 * @param {boolean} hdpi
						 */
						
						function(changed, breakpoint, hdpi) {
							
							// Variables
							var base = $(document.body).css('font-size');
							
							// List sliders
							$('.list .list-item > .row > .paging .slick-nav').each(function(i, e) {
								
								// Normalizes the element
								e = $(e);
								
								// Variables
								var length = e.find('.dot').length,
									width  = Math.min(5, Math.max(1, length)) *(Math.ceil(parseFloat(base)) * 1.7);
								
								// Set the slick container width
								e.width(Math.ceil(width))
								
							});
							
						},
						
						true);
				
				// List sliders
				$('.list .list-item > .row > .col').each(function(i, e) {
					
					// Normalizes the element
					e = $(e);
					
					// Variables
					var paging = e.parent().find('.slick-nav:not(.skip)'),
						dots = e.parent().find('.slick-nav').find('.dot'),
						slicked = false;
					
					// Initializes the slick
					e.slick({
						dots         : false,
						arrows       : true,
						infinite     : false,
						responsive   : [
							{
								breakpoint : 991,
								settings   : {
									arrows : true
								}
							},
							{
								breakpoint : 767,
								settings   : {
									arrows : false,
								}
							}
						]
					});
					
					// Hooks fake dots actions
					dots.click(function() {
						
						// Variables
						var current = $(this),
							paging  = current.closest('.slick-nav'),
							index   = paging.hasClass('skip') === false ? paging.find('.dot').index(current) + 1 : 0;
						
						// Update the dots
						updateDots(paging.parent(), index);
						
						// Nav to selected index
						e.slick('slickGoTo', index);
						
					});
					
					// If there are more than 5 dots to show
					if(paging.find('.dot').length > 5) {
						
						// Paging
						paging.slick({
							dots           : false,
							arrows         : false,
							infinite       : false,
							slidesToShow   : 5,
							slidesToScroll : 1
						});
						
						// Slicked
						slicked = true;
						
					} else {
						
						// Creates a container to align items
						paging.addClass('arrange').append(jQuery('<div />').append(paging.find('.dot')));
						
					}
					
					// Hooks the slick change to change the dots slick instance
					e.on('afterChange', function(event, slick, current) {
						
						// Update the dot selection
						updateDots(e.parent(), current);
						
						// Set the selected slick slide for the dots slick
						if(slicked === true) paging.slick('slickGoTo', Math.max(0, current - 1));
						
					});
					
				});
				
			})();
			
			// Mode toggler (mobile only)
			$('.switch .switch-list').on('click', 'a', function() {
				var element = $(this),
					parent  = element.parent(),
					mode    = parent.data('mode'),
					list    = $('.container-fluid');
				parent.parent().find('li').not(parent).removeClass('selected');
				parent.addClass('selected');
				switch(mode) {
					case 'map':
						list.addClass('map-mode');
						break;
					default:
						list.removeClass('map-mode');
						break;
				}
			});
			
			// Initializes the map
			scope.initSearchMap = function() {
				if(scope.layoutready === false) {
					setTimeout(scope.initSearchMap, 100);
					return;
				}
				var position  = new google.maps.LatLng(-34.86915493819817, -56.19656503200531),
					map       = new google.maps.Map(document.getElementById('map'), {
						center             : position,
						zoom               : 14,
						mapTypeId          : google.maps.MapTypeId.ROADMAP,
						panControl         : false,
						zoomControl        : !(spy.breakpoint.current == 'xs'),
						mapTypeControl     : false,
						scaleControl       : false,
						streetViewControl  : false,
						overviewMapControl : false,
						scrollwheel        : false
					}),
					icon      = {
						url        : '/assets/images/map-marker.png',
						size       : new google.maps.Size(50 * .75, 57 * .75),
						origin     : new google.maps.Point(0, 0),
						anchor     : new google.maps.Point((50 * .75) / 2, 57 * .75),
						scaledSize : new google.maps.Size(50 * .75, 57 * .75)
					},
					marker    = new google.maps.Marker({
						icon     : icon,
						position : position,
						map      : map
					}),
					container = document.createElement('div'),
					content   = document.createElement('div'),
					button, classname, display = 'block';
				if(/xs|sm|md/.test(spy.breakpoint.current)) {
					button    = jQuery('<div class="refresh"><i></i> Refrescar el mapa</div>');
					classname = 'refresh';
					display   = 'none';
					map.addListener('dragend', function() {
						container.style.display = 'block';
					});
				} else {
					button    = jQuery('<div><input type="checkbox" id="checkbox"><label for="checkbox"></label>Buscar en esta zona</div>');
					classname = 'form';
				}
				content.innerHTML = button.html();
				container.appendChild(content);
				container.className = classname;
				container.index = 1;
				container.style.display = display;
				map.controls[google.maps.ControlPosition.TOP_LEFT].push(container);
			}
			
			break;
		
		/**
		 * Projects
		 */
			
		case 'project':
			
			// Variables
			var amenities;
			
			// Expandables
			jQuery('.typologies').expandable({
				items        : '.item',
				hideafter    : 3,
				constructors : {
					toggler : {
						hidden  : '<div class="row typologies-toggler"><div class="col">{total} tipologías en total ({hidden} más) <a href="javascript:;" class="show-more">{label}</a></div></div>',
						visible : '<div class="row typologies-toggler"><div class="col"><a href="javascript:;" class="show-more">{label}</a></div></div>'
					}
				},
				language     : {
					more : 'Ver todas',
					less : 'Ver menos'
				}
			});
			
			// Typologias fake links
			jQuery('.typologies .item a.full-link').on({
				'mouseover' : function() {
					if(/lg|xl/.test(spy.breakpoint.current)) {
						var e = $(this);
						e.parent()
							.find('a:not(.full-link)')
							.addClass('hover');
					}
				},
				'mouseout'  : function() {
					if(/lg|xl/.test(spy.breakpoint.current)) {
						var e = $(this);
						e.parent()
							.find('a:not(.full-link)')
							.removeClass('hover');
					}
				}
			});
			
			// Amenities
			(function() {
				
				// Amenities container
				amenities = jQuery('.amenities');
				
				// Masonry layouts
				amenities.masonry({
					itemSelector    : '.col',
					gutter          : '.gutter',
					percentPosition : true
				});
				
				// Mode toggler (mobile only)
				$('#amenities .switch-list').on('click', 'a', function() {
					var element = $(this),
						parent  = element.parent(),
						mode    = parent.data('mode'),
						hidden  = amenities.find('li.disabled');
					parent.parent().find('li').not(parent).removeClass('selected');
					parent.addClass('selected');
					switch(mode) {
						case 'available':
							hidden.hide();
							break;
						default:
							hidden.show();
							break;
					}
					amenities.masonry('layout');
				});
				
				// Hide non available
				amenities.find('li.disabled').hide();
				
			})();
			
			// Mobile nav dropdown
			$('#sticky-subnav .dropdown, .section-header .dropdown').dropdown({
				triggers   : '.label',
				containers : 'ul',
				native     : false,
				options    : 'li a',
				autoheight : true
			});
			
			// Hides the dropdowns on navigation activation changes
			$(new CRD.FixedNav('#sticky-subnav')).on({
				'crd.fixednav.active'   : function() {
					$('.section-header .dropdown').dropdown('closeAll');
				},
				'crd.fixednav.inactive' : function() {
					$('#sticky-subnav .dropdown').dropdown('closeAll');
				}
			});
			
			// Blog slider
			$('#blog .blog-list').slick({
				infinite     : false,
				arrows       : true,
				dots         : false,
				slidesToShow : 3,
				responsive   : [
					{
						breakpoint : 991,
						settings   : {
							slidesToShow : 2,
							arrows       : true,
							dots         : false
						}
					},
					{
						breakpoint : 767,
						settings   : {
							slidesToShow : 1,
							arrows       : false,
							dots         : true
						}
					}
				]
			});
			
			// Equal height slides
			$('#blog .blog-list').on('setPosition', function() {
				var track, height;
				$(this).find('.slick-slide').height('auto');
				track = $(this).find('.slick-track');
				height = $(track).height();
				$(this).find('.slick-slide').css('height', height + 'px');
			});
			
			// Initializes the map
			scope.initProjectMap = function() {
				var element  = jQuery('.location-map'),
					position = new google.maps.LatLng(element.data('map-lat'), element.data('map-lng')),
					map      = new google.maps.Map(document.getElementById('map'), {
						center             : position,
						zoom               : 14,
						mapTypeId          : google.maps.MapTypeId.ROADMAP,
						panControl         : false,
						zoomControl        : !(spy.breakpoint.current == 'xs'),
						mapTypeControl     : false,
						scaleControl       : false,
						streetViewControl  : false,
						overviewMapControl : false,
						scrollwheel        : false
					}),
					panorama = map.getStreetView(),
					icon     = {
						url        : '/assets/images/map-marker.png',
						size       : new google.maps.Size(50 * .75, 57 * .75),
						origin     : new google.maps.Point(0, 0),
						anchor     : new google.maps.Point((50 * .75) / 2, 57 * .75),
						scaledSize : new google.maps.Size(50 * .75, 57 * .75)
					},
					marker   = new google.maps.Marker({
						icon     : icon,
						position : position,
						map      : map
					});
				
				panorama.setPosition(position);
				panorama.setVisible(false);
				
				// Mode toggler (mobile only)
				$('.location-map .switch-list').on('click', 'a', function() {
					var element = $(this),
						parent  = element.parent(),
						mode    = parent.data('mode'),
						hidden  = amenities.find('li.disabled'),
						status  = panorama.getVisible();
					parent.parent().find('li').not(parent).removeClass('selected');
					parent.addClass('selected');
					switch(mode) {
						case 'map-view':
							if(status === true) panorama.setVisible(false);
							break;
						default:
							if(status === false) panorama.setVisible(true);
							break;
					}
				});
				
			};
			
			// Galleries
			(function() {
				
				// Variables
				var galleries = [
					{
						puzzle   : '.renders-gallery',
						images   : scope.renders,
						switch   : '.gallery.renders .switch',
						fancybox : {
							gallery : 'renders'
						}
					},
					{
						puzzle   : '.construction-gallery',
						images   : scope.construction,
						switch   : '.gallery.construction .switch',
						fancybox : {
							gallery : 'construction'
						}
					}
				],
					maingallery = [];
				
				// Build main gallery
				for(var x = 0, images = galleries[0].images, max = images.length; x < max; x = x + 1) {
					maingallery.push({ src : images[x].extra.target });
				}
				
				// Header gallery trigger
				$('.header a').click(function() {
					$.fancybox.open(maingallery, {
						fullScreen : {
							autoStart : true // Request fullscreen mode on opening
						},
					});
				});
				
				// Initialize all galleries
				$.each(galleries, function(i, gallery) {
					
					// Initialize the puzzle grid
					var puzzle = new CRD.PuzzleImageGrid(gallery.puzzle, gallery.images, {
						percentage   : true,
						targetheight : 50,
						gap          : 2,
						constructors : {
							image : '<div class="col-auto"><a data-fancybox="' + gallery.fancybox.gallery + '" href="{target}"><img class="puzzle-image" /></a><div class="loading"><div><div></div><div></div><div></div></div></div></div>'
						},
						on           : {
							'crd.puzzleimagegrid.draw'        : function(e, from, to, total) {
								
								// Show hide 'show more' button
								$(gallery.switch)[to === total ? 'hide' : 'show']();
								
							},
							'crd.puzzleimagegrid.imageloaded' : function(e, image) {
								
								// Variables
								var loading = $(image).parent().parent().find('.loading');
								
								// Fades out the loading animation and removes it from the DOM
								loading.fadeOut(200, function() {
									loading.remove();
								});
								
							}
						}
					});
					
					// Load more images
					$(gallery.switch + ' a').click(function() {
						puzzle.draw('next page');
					});
					
					// Waits for image grid to start to hook fancybox
					$(puzzle).on('crd.puzzleimagegrid.draw', function() {
						
						// Initializes fancybox
						$('[data-fancybox="' + gallery.fancybox.gallery + '"]').fancybox({
							fullScreen : {
								autoStart : true // Request fullscreen mode on opening
							},
							afterShow  : function(instance) {
								
								// If the group does not have all the available images
								if(instance.group.length < gallery.images.length) {
									
									// Add the remaining images to the group
									for(var x = instance.group.length - 1, max = gallery.images.length; x < max; x = x + 1) {
										
										// Adds the image to the group
										instance.createGroup({
											type : 'image',
											src  : gallery.images[x].extra.target
										});
										
									}
									
								}
								
							}
						});
						
					}.once());
					
				});
				
				// Breakpoint change hook
				actions
					.add(
						
						/**
						 * Changes all galleries configuration for each device
						 * @param {boolean} changed
						 * @param {String}  breakpoint
						 * @param {boolean} hdpi
						 */
						
						function(changed, breakpoint, hdpi) {
							
							// Variables
							var height, rows;
							
							// If breakpoint changes
							if(changed === true) {
								
								// Depending on each breakpoint
								switch(breakpoint) {
									
									case 'xl':
									case 'lg':
									case 'md':
										
										// Sets the new row height
										height = (265 * 100) / 1440;
										rows   = 2;
										
										break;
									
									default:
										
										// Sets the new row height
										height = 50;
										rows   = 3;
										
										break;
									
								}
								
								// For each gallery
								$.each(galleries, function(i, gallery) {
									
									// Variables
									var puzzle = $(gallery.puzzle).puzzleImageGrid('get');
									
									// Sets the new row height and re-draws the grid
									puzzle.setOptions({
										targetheight : height,
										visiblerows  : rows
									}).draw(0, puzzle.lastindex, true);
									
								});
								
							}
							
						},
						
						true);
				
				// Planus and masterplan fancybox initialization options
				$('[data-fancybox="masterplan"], [data-fancybox="planus"]').fancybox({
					fullScreen : {
						autoStart : true // Request fullscreen mode on opening
					}
				});
				
			})();
			
			// Planuses sliders
			(function() {
				
				// Variables
				var planuses = $('.planus');
				
				// For each planus slider
				planuses.each(function(i, e) {
					
					// Normalizes element
					e = $(e);
					
					// Variables
					var gallery = e.find('.gallery'),
						nav     = e.find('ul.switch-list li');
					
					// Slick
					gallery.slick({
						infinite     : false,
						arrows       : false,
						dots         : false,
						slidesToShow : 1
					});
					
					// Hooks to slider change
					gallery.on('beforeChange', function(e, s, c, slide) {
						nav.removeClass('selected');
						$(nav[slide]).addClass('selected');
					});
					
					// Hooks to switch click
					nav.on('click',function(e) {
						e.preventDefault();
						nav.removeClass('selected');
						$(this).addClass('selected');
						gallery.slick('slickGoTo', nav.index(this));
					});
					
				});
				
			})();
			
			// Units tables
			$('.units table').DataTable({
				info       : false,
				paging     : false,
				searching  : false,
				autoWidth  : false,
				responsive : {
					breakpoints : [
						{
							name  : 'xl',
							width : Infinity
						},
						{
							name  : 'lg',
							width : 1199
						},
						{
							name  : 'md',
							width : 991
						},
						{
							name  : 'sm',
							width : 767
						},
						{
							name  : 'xs',
							width : 576
						}
					]
				},
				columnDefs : [
					{
						targets   : [-2],
						orderable : false
					},
					{
						targets   : [-1],
						orderable : false
					}
				]
			});
			
			// Prevents hidden dataTables data to display
			$('.units table .unit-number a').click(function(event) {
				event.stopPropagation();
			});
			
			// Project sticky
			(function() {
				
				// Variables
				var mainnav = $('#sticky-nav'),
					subnav = $('#sticky-subnav'),
					sticky = new CRD.FixedNav('#sticky-subnav'),
					headers = $('.typology'),
					visible, active;
				
				/**
				 * Get visible typologies
				 * @return {Object}
				 */
				
				function getVisible()  {
					
					// Variables
					var visible = [];
					
					// For each typology
					headers.each(function(i, e) {
						
						// Normalizes object
						e = $(e);
						
						// If the object is in between the viewport visible area, adds the object to the returning array
						if(e.visible(true) === true) visible.push(e);
						
					});
					
					// Return visible elements
					return visible;
					
				}
				
				/**
				 * Sets the sticky subnav style
				 * @param {Object} typology - Typology to mimic
				 */
				
				function updateSticky(typology) {
					
					// Variables
					var ul = subnav.find('.title ul'),
						actions = subnav.find('.tools ul li'),
						li;
					
					// Toggle actions
					actions.toggleClass('d-none');
					
					// If the typology is defined
					if(typeof typology !== 'undefined') {
						
						if(ul.find('li').length > 1) ul.find(':last-child').remove();
						
						// Adds the control class
						subnav.addClass('typology');
						
						// Creates the typology container
						li = $('<li />')
							.text(typology.find('.title ul li:last-child').text());
						
						// Sets the title
						ul.append(li.hide());
						
						// Fades in the typology container
						li.fadeIn(200);
						
					} else {
						
						// Adds the control class
						subnav.removeClass('typology');
						
						// Removes the typology information from the title
						ul.find('li:last-child').fadeOut(200, function() {
							$(this).remove();
						});
						
					}
					
				}
				
				// Hooks to scroll to specifically show or hide menu on certain areas
				$(new CRD.ScrollSpy()).on({
					'crd.scrollspy.up'   : function(e, scrollY) {
						
						// Variables
						var visible = getVisible(), // Get all visible typologies
							mnheight = mainnav.outerHeight(),// Main nav height
							snheight = subnav.outerHeight(), // Subnav height
							active = visible.length === 0 ? [] : $(visible[visible.length === 1 ? 0 : (scrollY < $(visible[1]).offset().top - (mnheight + snheight + parseInt($(visible[0]).css('margin-bottom'))) ? 0 : 1)]), // Sets active typology
							aheight, // Active typology height
							ahheight,
							aoffset; // Active typology offset
						
						// If the sticky bar is active and there are any active typologies
						if(sticky.active === true && active.length > 0) {
							
							// Active typology height and offset
							aheight = active.outerHeight();
							aoffset = active.offset().top;
							
							// Typology header height
							ahheight = active.find('.section-header').outerHeight();
							
							// If the sticky is visible and it scrolls outside (up) the active typology
							if(scrollY + snheight + mnheight < aoffset + ahheight) {
								
								// If the sticky has not been updated
								if(subnav.hasClass('typology')) {
									
									// Restore proyect sticky
									updateSticky();
									
								}
							
							}
							
							// If the scroll reaches the active typology
							else if(scrollY + snheight + mnheight <= aoffset + aheight) {
								
								// If the sticky has not been updated
								if(subnav.hasClass('typology') === false) {
									
									// Sets the typology sticky
									updateSticky(active);
									
								}
								
							}
							
						}
						
					},
					'crd.scrollspy.down' : function(e, scrollY) {
						
						// Variables
						var visible = getVisible(), // Get all visible typologies
							snheight = subnav.outerHeight(), // Subnav height
							active = $(visible[sticky.fixed.is(':visible') ? 0 : visible.length - 1]), // Sets active typology
							aheight, // Active typology height
							ahheight,
							aoffset; // Active typology offset
						
						// If the sticky bar is active
						if(sticky.active === true && active.length > 0) {
							
							// Active typology height and offset top
							aheight = active.outerHeight();
							aoffset = active.offset().top;
							
							// Typology header height
							ahheight = active.find('.section-header').outerHeight();
							
							// If the sticky is visible and it scrolls outside (down) the active typology
							if(scrollY > aoffset + aheight - snheight) {
								
								// If the sticky has not been updated
								if(subnav.hasClass('typology')) {
									
									// Restore proyect sticky
									updateSticky();
									
								}
								
							}
							
							// If the scroll reaches the active typology
							else if(scrollY + snheight >= aoffset + ahheight && scrollY <= aoffset + aheight - snheight) {
								
								// If the sticky has not been updated
								if(subnav.hasClass('typology') === false) {
									
									// Sets the typology sticky
									updateSticky(active);
									
								}
								
							}
							
						}
						
					}
				});
				
				// Updates the sticky to the active typology
				visible = getVisible();
				active = $(visible[sticky.fixed.is(':visible') ? 0 : visible.length - 1]);
				if(active.length > 0) updateSticky(active);
			
			})();
			
			break;
		
		/**
		 * Your selection
		 */
		
		case 'your-selection':
			
			// Highlighted slider
			$('.buildings .list, .units .list').slick({
				infinite     : false,
				arrows       : true,
				dots         : false,
				slidesToShow : 4,
				responsive   : [
					{
						breakpoint : Infinity,
						settings   : 'unslick'
					},
					{
						breakpoint : 767,
						settings   : {
							slidesToShow : 1,
							arrows       : false,
							dots         : true
						}
					}
				]
			});
			
			// Breakpoint change hook
			actions
				.add(
					
					/**
					 * Creates or destroys the expandable lists
					 * @param {boolean} changed
					 * @param {String}  breakpoint
					 * @param {boolean} hdpi
					 */
					
					function(changed, breakpoint, hdpi) {
						
						// Variables
						var lists;
						
						// If breakpoint changes
						if(changed === true) {
							
							// List
							lists = $('.buildings .list, .units .list');
							
							// Switch between different breakpoint
							switch(breakpoint) {
								
								// Mobile
								case 'xs':
								case 'sm':
									
									// If any of the expandables has been created
									if(typeof $(lists[0]).expandable('get') !== 'undefined') {
										
										// Destroys the expandable lists
										lists.each(function(i, e) {
											$(e).expandable('destroy');
										});
										
									}
									
									break;
									
								// Desktop and tablet
								default:
									
									// If the expandables has not been initialized
									if(typeof $(lists[0]).expandable('get') === 'undefined') {
										
										// Expandables
										lists.each(function(i, e) {
											
											// Normalizes element
											e = $(e);
											
											// Create the exapandable list
											e.expandable({
												items        : '.list-item',
												hideafter    : /md/.test(breakpoint) ? 3 : 4,
												animated     : false,
												constructors : {
													toggler : {
														hidden  : '<div class="row toggler"><div class="col">{total} {type} en total ({hidden} más) <a href="javascript:;" class="show-more">{label}</a></div></div>'.substitute({ type : e.data('type-nomenclator') }),
														visible : '<div class="row toggler"><div class="col"><a href="javascript:;" class="show-more">{label}</a></div></div>'
													}
												},
												language     : {
													more : 'Ver todos',
													less : 'Ver menos'
												}
											});
											
										});
										
									}
									
									break;
								
							}
							
						}
						
					},
					
					true);
			
			break;
		
		/**
		 * Compare
		 */
		
		case 'compare':
			
			// Mobile nav dropdown
			$('.subnav .dropdown, .section-header .dropdown').dropdown({
				triggers   : '.label',
				containers : 'ul',
				native     : false,
				options    : 'li a',
				autoheight : true
			});
			
			// Fake fixed table
			(function() {
				
				// Variables
				var table    = $('.main-table .table-container'),
					content  = table.find('.table-content'),
					maxwidth = content.width(),
					width, cwidth, cmwidth, fixwidth, units,
					sticky = new CRD.FixedNav('#sticky-subnav');
				
				/**
				 * Calculate column widths based on max cotainer width and visible columns
				 * @param {Number} columns
				 * @param {Number} width
				 * @return {Number}
				 */
				
				function getColumnWidth(columns, width) {
					return Math.floor(width / columns);
				}
				
				// Adds the breakpoint action
				actions.add(
					
					/**
					 * Fake fixed table sizing
					 * @param {boolean} changed
					 * @param {String}  breakpoint
					 * @param {boolean} hdpi
					 */
					
					function(changed, breakpoint, hdpi) {
						
						// Variables
						maxwidth = content.width();
						width    = 'auto',
						cmwidth  = 'unset',
						fixwidth = '50%';
						
						// Switch between different breakpoint
						switch(breakpoint) {
							
							case 'xs':
							case 'sm':
								
								// Set the width for each column
								width = getColumnWidth(1, maxwidth);
								
								break;
							
							case 'md':
								
								// Fixed width
								fixwidth = '30%';
								
								// Set the width for each column
								width = getColumnWidth(4, maxwidth);
								
								break;
								
							default:
								
								// Get  units
								units = content.find('.table-header .table-column').length;
								
								// Content width
								cwidth = table.parent().innerWidth();
								
								// Fix width
								fixwidth = Math.min(
									220, // Desired width - cwidth * (breakpoint == 'lg' ? .15 : .25)
									cwidth - (units * 135) // Max available width
								);
								
								// Set max width
								cmwidth = fixwidth + (units * 135);
								
								// Set the width for each column
								width = 120;
								
								break;
							
						}
						
						// For each table
						$('.table-container').each(function(i, table) {
							
							// Normalizes table
							table = $(table);
							
							// Variables
							var content  = table.find('.table-content'),
								fixed    = table.find('.fixed'),
								columns  = content.find('.table-column'),
								rows     = content.find('.table-row');
							
							// Table container max width
							table.css('max-width', cmwidth);
							
							// Content offset
							fixed.css('width', fixwidth);
							
							// Content offset
							content.css({
								'width'       : isNaN(Number(fixwidth)) ? 'unset' : cmwidth - fixwidth,
								'margin-left' : fixwidth
							});
							
							// Set the width for all columns
							columns.css('width', width);
							
							// Modify rows height
							rows.each(function(i, row) {
								
								// Normalize row
								row = $(row);
								
								// Variables
								var related = $(table.find('.fixed .table-row')[i]),
									height  = Math.max(row.outerHeight(), related.outerHeight());
								
								// Set fixed columns heights
								related.css('height', height);
								row.css('height', height);
								
							});
							
						});
						
					},
					
				true);
				
				// Scroll coordination between sticky scrollable and table scrollable
				$('.main-table .table-container .table-content').scroll(function() {
					
					// Variables
					var scrollx = $(this).scrollLeft();
					
					// Duplicate scroll position for header
					$('#sticky-subnav .table-container .table-content').scrollLeft(scrollx);
					
				});
				
				// Fixed scrolling positions
				$('.main-table .table-container .table-content').scroll(function() {
				
					// Variables
					var scrollable = $(this),
						scrollx    = scrollable.scrollLeft(),
						scrollto   = Math.round(scrollx / width) * width;
					
					// Scrolls to closest element
					CRD.Utils.scrollElementTo(scrollable, scrollto, 'x', 500);
					CRD.Utils.scrollElementTo('#sticky-subnav .table-container .table-content', scrollto, 'x', 500);
				
				}.debounce(500));
				
				// Overrides sticky nav offset
				sticky.setOptions({
					offset : {
						bottom : function(direction, scrollY) {
							var table = $('.main-table .table-container .table-content');
							return table.offset().top + table.height() - sticky.fixed.height() - (direction === CRD.ScrollSpy.Scroll.UP ? $('#sticky-nav').height() : 0);
						}
					}
				});
				
				// Update scroll position when sticky is active
				$(sticky).on({
					'crd.fixednav.active' : function(e, fixed) {
						$(fixed).find('.table-content').scrollLeft($('.main-table .table-container .table-content').scrollLeft())
					}
				});
				
			})();
			
			// Initializes the map
			scope.initComparisonMap = function() {
				var element  = jQuery('.location-map'),
					position = new google.maps.LatLng(element.data('map-lat'), element.data('map-lng')),
					map      = new google.maps.Map(document.getElementById('map'), {
						center             : position,
						zoom               : 14,
						mapTypeId          : google.maps.MapTypeId.ROADMAP,
						panControl         : false,
						zoomControl        : !(spy.breakpoint.current == 'xs'),
						mapTypeControl     : false,
						scaleControl       : false,
						streetViewControl  : false,
						overviewMapControl : false,
						scrollwheel        : false
					}),
					icon     = {
						url        : '/assets/images/map-marker.png',
						size       : new google.maps.Size(50 * .75, 57 * .75),
						origin     : new google.maps.Point(0, 0),
						anchor     : new google.maps.Point((50 * .75) / 2, 57 * .75),
						scaledSize : new google.maps.Size(50 * .75, 57 * .75)
					},
					marker   = new CRD.Maps.CustomMarker(position, map, {
						classname : 'tooltip up',
						label     : '<span style="font-weight: bold;">Forum</span><br><span style="font-size: 0.8em; opacity: 0.7">604</span>'
					});
				google.maps.event.addDomListener(marker, 'click', function() {
					CRD.Utils.scrollToElement('#unidad-604');
				});
			};
			
			// Hides the dropdowns on navigation activation changes
			$(new CRD.FixedNav('#sticky-subnav')).on({
				'crd.fixednav.active'   : function() {
					$('.subnav .dropdown, .section-header .dropdown').each(function(i, e) {
						$(e).dropdown('closeAll');
					});
				},
				'crd.fixednav.inactive' : function() {
					$('.subnav .dropdown, .section-header .dropdown').each(function(i, e) {
						$(e).dropdown('closeAll');
					});
				}
			});
			
			// Units sticky
			(function() {
				
				// Variables
				var mainnav = $('#sticky-nav'),
					subnav = $('#units-sticky'),
					sticky = new CRD.FixedNav('#units-sticky', {
						initState  : CRD.FixedNav.Mode.CLOSE,
						positions  : {
							closed : 0,
							opened : function() {
								return mainnav.height();
							}
						},
						offset     : {
							top : function(direction) {
								return $('.typology:first-child').offset().top + (direction === 'down' ? 0 : -mainnav.outerHeight());
							}
						}
					}),
					headers = $('.typology'),
					init = false,
					visible, active;
				
				// Show/hides units sticky when active or inactive
				$(sticky).on({
					'crd.fixednav.active'   : function(e, fixed) {
						$(fixed).css({
							'display'    : 'block',
							'visibility' : 'visible'
						});
						$('.dropdown').each(function(i, e) {
							$(e).dropdown('closeAll');
						});
					},
					'crd.fixednav.inactive' : function(e, fixed) {
						$(fixed).css({
							'display'    : 'none',
							'visibility' : 'hidden'
						});
						$('.subnav .dropdown, .section-header .dropdown').each(function(i, e) {
							$(e).dropdown('closeAll');
						});
					}
				});
				
				/**
				 * Get visible typologies
				 * @return {Object}
				 */
				
				function getVisible()  {
					
					// Variables
					var visible = [];
					
					// For each typology
					headers.each(function(i, e) {
						
						// Normalizes object
						e = $(e);
						
						// If the object is in between the viewport visible area, adds the object to the returning array
						if(e.visible(true) === true) visible.push(e);
						
					});
					
					// Return visible elements
					return visible;
					
				}
				
				/**
				 * Sets the sticky subnav style
				 * @param {Object} typology - Typology to mimic
				 */
				
				function updateSticky(typology) {
					
					// Variables
					var title   = subnav.find('.title'),
						project = title.find('li:first-child'),
						unit    = title.find('li:last-child'),
						address = title.find('h5');
					
					// If the typology is defined
					if(typeof typology !== 'undefined') {
						
						// Set the texts
						project.text(typology.find('.title ul li:first-child').text());
						unit.text(typology.find('.title ul li:last-child').text());
						address.text(typology.find('.title h5').text());
						
						// Show sticky
						subnav.css({
							'display'    : 'block',
							'visibility' : 'visible'
						});
						
					} else {
						
						// Hides sticky
						subnav.css({
							'display'    : 'none',
							'visibility' : 'hidden'
						});
						
					}
					
					// Close all dropdowns
					$('.subnav .dropdown, .section-header .dropdown').each(function(i, e) {
						$(e).dropdown('closeAll');
					});
					
				}
				
				// Hooks to scroll to specifically show or hide menu on certain areas
				$(new CRD.ScrollSpy()).on({
					'crd.scrollspy.up'   : function(e, scrollY) {
						
						// Variables
						var visible = getVisible(), // Get all visible typologies
							mnheight = mainnav.outerHeight(),// Main nav height
							snheight = subnav.outerHeight(), // Subnav height
							active = visible.length === 0 ? [] : $(visible[visible.length === 1 ? 0 : (scrollY < $(visible[1]).offset().top - (mnheight + snheight + parseInt($(visible[0]).css('margin-bottom'))) ? 0 : 1)]), // Sets active typology
							aheight, // Active typology height
							ahheight,
							aoffset; // Active typology offset
						
						// If the sticky bar is active and there are any active typologies
						if(sticky.active === true && active.length > 0) {
							
							// Active typology height and offset
							aheight = active.outerHeight();
							aoffset = active.offset().top;
							
							// Typology header height
							ahheight = active.find('.section-header').outerHeight();
							
							// If the sticky is visible and it scrolls outside (up) the active typology
							if(scrollY + snheight + mnheight < aoffset + ahheight) {
								
								// If units sticky is visible
								if(subnav.is(':visible') === true) {
									
									// Restore proyect sticky
									updateSticky();
									
								}
								
							}
							
							// If the scroll reaches the active typology
							else if(scrollY + snheight + mnheight <= aoffset + aheight) {
								
								// If units sticky is not visible
								if(subnav.is(':visible') === false) {
									
									// Sets the typology sticky
									updateSticky(active);
									
								}
								
							}
							
						}
						
					},
					'crd.scrollspy.down' : function(e, scrollY) {
						
						// Variables
						var visible = getVisible(), // Get all visible typologies
							snheight = subnav.outerHeight(), // Subnav height
							active = $(visible[sticky.fixed.is(':visible') ? 0 : visible.length - 1]), // Sets active typology
							aheight, // Active typology height
							ahheight,
							aoffset; // Active typology offset
						
						// If the sticky bar is active
						if(sticky.active === true && active.length > 0) {
							
							// Active typology height and offset top
							aheight = active.outerHeight();
							aoffset = active.offset().top;
							
							// Typology header height
							ahheight = active.find('.section-header').outerHeight();
							
							// If the sticky is visible and it scrolls outside (down) the active typology
							if(scrollY > aoffset + aheight - snheight) {
								
								// If units sticky is visible
								if(subnav.is(':visible') === true) {
									
									// Restore proyect sticky
									updateSticky();
									
								}
								
							}
							
							// If the scroll reaches the active typology
							else if(scrollY + snheight >= aoffset + ahheight && scrollY <= aoffset + aheight - snheight) {
								
								// If units sticky is not visible
								if(subnav.is(':visible') === false || init === false) {
									
									// Disable the initialization flag
									init = true;
									
									// Sets the typology sticky
									updateSticky(active);
									
								}
								
							}
							
						}
						
					}
				});
				
				// Updates the sticky to the active typology
				visible = getVisible();
				active = $(visible[sticky.fixed.is(':visible') ? 0 : visible.length - 1]);
				if(active.length > 0) updateSticky(active);
				
			})();
			
			// Amenities
			(function() {
				
				// Amenities container
				amenities = jQuery('.amenities');
				
				// Masonry layouts
				amenities.masonry({
					itemSelector    : '.col',
					gutter          : '.gutter',
					percentPosition : true
				});
				
				// Mode toggler (mobile only)
				$('#amenities .switch-list').on('click', 'a', function() {
					var element = $(this),
						parent  = element.parent(),
						mode    = parent.data('mode'),
						hidden  = amenities.find('li.disabled');
					parent.parent().find('li').not(parent).removeClass('selected');
					parent.addClass('selected');
					switch(mode) {
						case 'available':
							hidden.hide();
							break;
						default:
							hidden.show();
							break;
					}
					amenities.masonry('layout');
				});
				
				// Hide non available
				amenities.find('li.disabled').hide();
				
			})();
			
			// Planuses sliders
			(function() {
				
				// Variables
				var planuses = $('.planus');
				
				// For each planus slider
				planuses.each(function(i, e) {
					
					// Normalizes element
					e = $(e);
					
					// Variables
					var gallery = e.find('.gallery'),
						nav     = e.find('ul.switch-list li');
					
					// Slick
					gallery.slick({
						infinite     : false,
						arrows       : false,
						dots         : false,
						slidesToShow : 1
					});
					
					// Hooks to slider change
					gallery.on('beforeChange', function(e, s, c, slide) {
						nav.removeClass('selected');
						$(nav[slide]).addClass('selected');
					});
					
					// Hooks to switch click
					nav.on('click',function(e) {
						e.preventDefault();
						nav.removeClass('selected');
						$(this).addClass('selected');
						gallery.slick('slickGoTo', nav.index(this));
					});
					
				});
				
			})();
			
			break;
		
	}
	
})(jQuery, this || window);