/* jshint -W058 */

/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.Dropdown = (function() {
	
	// Variables
	var constants = {
			Events    : {
				SELECT : 'crd.dropdown.select'
			}
		}; // Constants
	
	/**
	 * Submenu
	 * @class
	 * @param {String|Object} element - Menu element/container
	 * @return {Dropdown}
	 */
	
	function Dropdown(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data('crd.collapsable'); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options
			options = jQuery.extend(true, {
				
				// Close on select
				autoclose  : true,
				
				// Custom selectors
				triggers   : '.dropdown-trigger',
				containers : '.dropdown-container',
				
				// Enables / disable native support (needs some CSS to hide the default select)
				native     : false,
				options    : 'li', // Selector for list options
				
				// Auto height
				autoheight : false,
				
				// Toggle function  (overrides default)
				toggle     : function(mode) {
					
					// Shows or hides the submenu
					this.css(mode === CRD.Collapsable.Mode.OPEN ? {
						'visibility' : 'visible'
					} : {
						'visibility' : 'hidden'
					});
					
				},
				
				show       : function() {
					this.css('visibility', 'visible');
				},
				
				hide       : function() {
					this.css('visibility', 'hidden');
				},
				
				// Events
				on         : {
					'crd.collapsable.open' : function(e, t, m, o) {
						
						// Adds the opened class
						t.parent().addClass('dropdown-open');
						
					},
					'crd.collapsable.close' : function(e, t, m, o) {
						
						// removes the opened class
						t.parent().removeClass('dropdown-open');
						
					}
				}
				
			}, options);
			
			// Initializes the object
			self = CRD.Collapsable.apply(this, [element, options]);
			
			// For each submenu trigger
			this.triggers.each(function(i, trigger) {
				
				// Variables
				var index = i;
				
				// Sets the clickoutside handler
				jQuery(trigger).clickOutside(function() {
					
					// Closes the submenu
					self.close(index);
					
				}, jQuery(self.containers[i]));
				
				// Hooks to element content
				jQuery(self.containers[i]).on('click', options.options, function(event) {
					
					/**
					 * Triggers the option selection event
					 * @event crd.dropdown.select
					 */
					
					self.dispatch(constants.Events.SELECT, [
						jQuery(event.target),
						self
					]);
					
					// If auto-close dropdown is enabled
					if(options.autoclose === true) {
						
						// Closes the dropdown
						self.close(index);
						
					}
					
				});
				
			});
			
			// If height must be set automatically
			if(options.autoheight === true) {
			
				// Auto-height
				this.containers.each(function(i, container) {
				
					// Auto height
					jQuery(container).css({
						'height'     : 'auto',
						'max-height' : 'none'
					});
				
				});
			
			}
			
			// If native support is enabled
			if(options.native === true) {
				
				// Creates the native elements
				this.createSelect();
				
			}
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	// Inherits from CRD.Collapsable
	Dropdown.inherit(CRD.Collapsable);
	
	// Adds additional method to handle native select
	Dropdown.prototype.createSelect = function() {
		
		// Variables
		var options = jQuery(this.containers[0]).find(this.options.options),
			select = jQuery('<select />'),
			option = jQuery('<option />');
		
		// For each option
		options.each(function(i, e) {
			
			// Normalizes element
			e = jQuery(e);
			
			// Variables
			var o = option.clone();
			
			// Set options attributes
			o.text(e.text());
			
			// Adds the option to the select
			select.append(o);
			
		});
		
		// Sets font size (so on click, the browser won't zoom in to fit the select)
		select.css('font-size', '20px');
		
		// Adds the select
		this.element.append(select);
		
	};
	
	// Set object constants
	CRD.Utils.setConstants(Dropdown, constants);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(Dropdown, 'dropdown', 'crd.collapsable');
	
	// Return the object reference
	return Dropdown;
	
})();