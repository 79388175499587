/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.MobileNav = (function() {
	"use strict";
	
	// Variables
	var constants = {
			Mode   : {
				OPEN  : 'open',
				CLOSE : 'close'
			},
			Events : {
				OPEN  : 'crd.mobilenav.open',
				CLOSE : 'crd.mobilenav.close'
			}
		}, // Constants
		storage = {
			main  : 'crd.mobilenav',
			state : 'crd.mobilenav.state'
		}; // Data storage
	
	/**
	 * MobileNav
	 * @param {Object|String} fixed     - Fixed bar element
	 * @param {Object|String} trigger   - Trigger element
	 * @param {Object|String} container - Container element
	 * @param {Object}        options   - Options for the FixedNav instance
	 * @class
	 */
	
	function MobileNav(fixed, trigger, container, options) {
		
		// Variables
		var element = jQuery(fixed), // Element reference
			self = element.data(storage.main); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Apply base constructor
			CRD.FixedNav.apply(this, [fixed, options]);
			
			// Sets the trigger and the container
			this.trigger = jQuery(trigger);
			this.container = jQuery(container);
			
			// Initializes the object (after the parent initialization, so this.fixed is present in the object)
			this.MobileNav();
			
			// Stores the object reference in the element storage
			this.fixed.data(storage.main, this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	};
	
	// Inherits from CRD.FixedNav
	MobileNav.inherit(CRD.FixedNav);
	
	// Extends prototype with own functions
	MobileNav.prototype = jQuery.extend(MobileNav.prototype, {
		
		/**
		 * Initialized mobile navigation.
		 * @constructor
		 * @memberof MobileNav
		 * @public
		 */
		
		MobileNav : function() {
			
			// Creates the event handler
			this.clickHandler = function() {
				this.toggleNav();
			}.bind(this);
			
			// Adds the evento to the trigger
			this.trigger.on('click', this.clickHandler);
			
			// Sets the clickoutside handler
			jQuery(this.container).clickOutside(function() {
				this.close();
			}.bind(this), this.trigger);
			
			// Sets initial state
			this.fixed.data(storage.state, constants.Mode.CLOSE);
			
		},
		
		/**
		 * Closes navigation.
		 * @method close
		 * @fires crd.mobilenav.close
		 * @memberOf MobileNav
		 * @public
		 */
		
		close : function() {
			
			// Variables
			var state = this.fixed.data(storage.state); // Current state
			
			// If the navigation is already opened
			if(state === constants.Mode.OPEN) {
				
				// Change overflow state
				jQuery(document.body)
					.css('overflow', 'visible');
				
				// Hides the container
				this.container.css('display', 'none');
				
				// Sets the state
				this.fixed.data(storage.state, constants.Mode.CLOSE);
				
				/**
				 * Triggers event
				 * @event crd.mobilenav.close
				 */
				
				this.dispatch(constants.Events.CLOSE, [
					this.trigger,
					this.container,
					this
				]);
				
			}
			
		},
		
		/**
		 * Toggles navigation open/close.
		 * @method toggleNav
		 * @fires crd.mobilenav.open
		 * @fires crd.mobilenav.close
		 * @memberOf MobileNav
		 * @public
		 */
		
		toggleNav : function() {
			
			// Variables
			var current = this.fixed.data(storage.state), // current status
				event = current === constants.Mode.OPEN ? constants.Events.CLOSE : constants.Events.OPEN, // Default event to trigger
				overflow = event === constants.Events.CLOSE ? 'visible' : 'hidden', // Default overflow state
				state = current === constants.Mode.OPEN ? constants.Mode.CLOSE : constants.Mode.OPEN; // State
			
			// Change overflow state
			jQuery(document.body).css('overflow', overflow);
			
			// Toggles container visibility
			/*this.container.fadeToggle(400, 'swing', function() {
				
				// Sets the state
				this.element.data(storage.state, state);
				
			}.bind(this));*/
			
			// Show or hides the container
			//this.container[current === constants.Mode.OPEN ? 'hide' : 'show']();
			this.container.css('display', current === constants.Mode.OPEN ? 'none' : 'flex');
			
			// Sets the state
			this.fixed.data(storage.state, state);
			
			/**
			 * Triggers event
			 * @event crd.mobilenav.open
			 * @event crd.mobilenav.close
			 */
			
			this.dispatch(event, [
				this.trigger,
				this.container,
				this
			]);
			
		},
		
		/**
		 * Destoys the object and unhooks the events
		 * @method destroy
		 * @memberOf MobileNav
		 * @public
		 */
		
		destroy : function() {
			
			// Unhooks FixedNav and ScrollSpy events
			CRD.FixedNav.prototype.destroy.apply(this);
			
			// Change overflow state
			jQuery(document.body).css('overflow', 'visible');
			
			// Removes the event from the trigger
			this.trigger.off('click', this.clickHandler);
			
			// Removes the object reference from element storage
			this.fixed.removeData(storage.main);
			
		}
		
	}, CRD.ClassUtils);
	
	// Set object constants
	CRD.Utils.setConstants(MobileNav, constants);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(MobileNav, 'mobilenav', storage.main);
	
	// Returns the constructor
	return MobileNav;
	
})();