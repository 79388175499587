/* jshint -W058 */

/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.Tooltips = (function(context) {
	"use strict";
	
	// Variables
	var constants = {
			Direction : {
				UP   : 'up',
				DOWN : 'down'
			}
		}, // Constants
		storage = {
			main : 'crd.tooltip',
			text : 'crd.tooltip.text',
			html : {
				text     : 'tooltip',
				position : 'tooltip-position'
			}
		}, // Data and HTML storage
		extend = jQuery.extend,
		body = jQuery(document.body);
	
	/**
	 * Tooltips
	 * @class
	 * @param {String|Object} selector - Selector to apply, default to [title], [data-tooltip] (every element with a title or data tooltip
	 * @param {Object}        options  - Options to override default tooltips options.
	 * @return {Tooltips}
	 */
	
	function Tooltips(selector, options) {
		
		// Set options and events
		this.setOptions(options);
		
		// Stores the passed value for selector as an option
		this.options.selector = selector || this.options.selector;
		
		/**
		 * Tooltip collection
		 * @property {Object} tooltips - Tooltip collection
		 */
		
		this.tooltips = [];
		
		// Initializes the object
		this.Tooltips(selector || this.options.selector);
		
		// Returns object reference
		return this;
		
	}
	
	// Tooltips prototype implements ClassUtils
	
	Tooltips.prototype = extend({
		
		/**
		 * Default options
		 * @property {Object}   defaults              - Default options
		 * @property {String}   defaults.hideafter    - Hide after that number of characters
		 */
		
		defaults : {
			selector     : '[data-tooltip]',
			hideafter    : 150,
			position     : constants.Direction.UP,
			offset       : {
				x : 10,
				y : 10
			},
			constructors : {
				tooltip  : '<div class="tooltip">{text}</div>'
			}
		},
		
		/**
		 * Initializes tooltips manager
		 * @constructor
		 * @param {String|Object} selector - Selector to bind the tooltips event
		 * @memberof Tooltips
		 * @public
		 */
		
		Tooltips : function(selector) {
			
			// Hooks the event
			body.on('mouseover', selector, this.show.bind(this));
			
		},
		
		/**
		 * Shos the tooltip for the element
		 * @method show
		 * @param {Object} event - Mouseover event
		 * @memberOf Tooltips
		 * @public
		 */
		
		show : function(event) {
			
			// Prevents default
			event.preventDefault();
			
			// Variables
			var target = event.target,
				instance = jQuery(event.target).tooltip('get');
			
			// Hide all tooltips
			this.batch('hide', [null, true]);
			
			// If the tooltip has not been created
			if(typeof instance === 'undefined') {
				
				// Creates the tooltip for the target
				instance = new Tooltip(target, {
					hideafter   : this.options.hideafter,
					position    : this.options.position,
					offset      : this.options.offset,
					constructor : this.options.constructors.tooltip
				});
				
				// Push the instance to the tooltip collection
				this.tooltips.push(instance);
				
			} else {
				
				// Show the tooltip
				instance.show();
				
			}
			
		},
		
		/**
		 * Applies batch functions to all tooltips
		 * @method batch
		 * @param {String|Function} func - Function to apply to each tooltip instance
		 * @param {Object}          args - Arguments for the callable
		 * @memberOf Tooltips
		 * @public
		 */
		
		batch : function(func, args) {
			
			// For each registered tooltip
			for(var x = 0, max = this.tooltips.length; x < max; x = x + 1) {
				
				// Hide the tooltip
				this.tooltips[x][func].apply(this.tooltips[x], args || []);
				
			}
			
		},
		
		/**
		 * Creates the tooltip container
		 * @method createContainer
		 * @param {String} constructor - Tooltip comnstructor
		 * @param {String} position    - Tooltip position (added as class name)
		 * @param {String} text        - Tooltip text
		 * @memberOf Tooltips
		 * @return {Object}
		 * @public
		 */
		
		createContainer : function(constructor, position, text) {
			
			// Variables
			var tooltip = jQuery(constructor.substitute({
				text : text
			}));
			
			// Adds the position class
			tooltip.addClass(position);
			
			// (:
			return tooltip;
			
		},
		
		/**
		 * Destroys the tooltips intance
		 * @method destroy
		 * @memberOf Tooltips
		 * @public
		 */
		
		destroy : function() {
			
			// Hide all tooltips
			this.batch('destroy', []);
			
			// Unhooks the event
			body.off('mouseover', this.options.selector, this.show.bind(this));
			
		}
		
	}, CRD.ClassUtils);
	
	// Set object constants
	CRD.Utils.setConstants(Tooltips, constants);
	
	/**
	 * Tooltip
	 * @class
	 * @param {String|Object} element - Element
	 * @param {Object}        options - Options to override default tooltip options.
	 * @return {Tooltip}
	 */
	
	function Tooltip(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data(storage.main), // Data storage reference
			defaults = {}, data;
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Gets the data attribute position value
			data = element.data(storage.html.position);
			
			// If the default value is set
			if(typeof data !== 'undefined') defaults.position = data;
			
			// Set options and events
			this.setOptions(extend(true, options, defaults));
			
			/**
			 * Text container (should be a text node element)
			 * @property {Object} element - Text node element
			 */
			
			this.element = element;
			
			/**
			 * Tooltip container
			 * @property {Object} container - Tooltip container
			 */
			
			this.container = null;
			
			/**
			 * Tooltip text
			 * @property {String} text - Tooltip text
			 */
			
			this.text = null;
			
			/**
			 * Hide tooltip timeout reference
			 * @property {Int} hidetimeout - Timeout refernce
			 */
			
			this.hidetimeout = null;
			
			// Stores the object reference in the element storage
			this.element.data(storage.main, this);
			
			// Initializes the object
			this.Tooltip();
			
			// Sets data reference
			self = this;
			
		}
		
		// Shows the tooltip
		self.show();
		
		// Returns object reference
		return self;
		
	}
	
	// Tooltip prototype implements ClassUtils
	
	Tooltip.prototype = extend({
		
		/**
		 * Default options
		 * @property {Object}   defaults              - Default options
		 */
		
		defaults : {
			hideafter    : Tooltips.prototype.defaults.hideafter,
			position     : constants.Direction.UP,
			offset       : Tooltips.prototype.defaults.offset,
			constructors : Tooltips.prototype.defaults.constructors.tooltip
		},
		
		/**
		 * Initializes the tooltip instance
		 * @constructor
		 * @memberof Tooltip
		 * @public
		 */
		
		Tooltip : function() {
			
			// Variables
			var text = this.element.data(storage.html.text);
			
			// Uses the data set tooltip or the title
			text = typeof text === 'undefined' ? this.element.attr('title') : text;
			
			// If the tooltip text is not set or hasnt been stored
			if(typeof this.element.data(storage.text) === 'undefined') {
				
				// Stores the tooltip text
				this.element.data(storage.text, text);
				
			}
			
			// Removes the title attribute to prevent default behavior
			this.element.attr('title', '');
			
			// Sets the tooltip text reference
			this.text = text;
			
		},
		
		/**
		 * Shows the tooltip for the element
		 * @method show
		 * @memberOf Tooltip
		 * @public
		 */
		
		show : function() {
			
			// Variables
			var position = this.element.offset(),
				dimensions,
				top, left;
			
			// If the timeout is set
			if(this.hidetimeout !== null) {
				
				// Clears the timeout
				clearTimeout(this.hidetimeout);
				
			}
			
			// If the container has not been created
			if(this.container === null) {
				
				// Creates the tooltip
				this.container = Tooltips.prototype.createContainer(this.options.constructor, this.options.position, this.text);
				
				// Hooks the hide event
				this.element.mouseout(this.hide.bind(this));
				
			}
			
			// Measures the tooltip
			dimensions = this.container.measure();
				
			// Switch between different tooltips position
			switch(this.options.position) {
				
				// Down
				case constants.Direction.DOWN:
					
					// Set the tooltip position
					top  = position.top + this.element.height() + this.options.offset.y;
					left = position.left + (this.element.width() - dimensions.width) / 2;
					
					break;
				
				// Up
				case constants.Direction.UP:
				default:
					
					// Set the tooltip position
					top  = position.top - dimensions.height - this.options.offset.y;
					left = position.left + (this.element.width() - dimensions.width) / 2;
					
					break;
				
			}
			
			// Sets the tootip position
			this.container.css({
				top  : top,
				left : left
			});
			
			// Attaches the container to the dom
			body.append(this.container);
			
		},
		
		/**
		 * Hides the tooltip for the element
		 * @method hide
		 * @param {Object}  event - Mouseout event
		 * @param {Boolean} hide  - Hide inmediately?
		 * @memberOf Tooltip
		 * @public
		 */
		
		hide : function(event, hide) {
			
			// Noramiles the param
			hide = hide || false;
			
			// If the tooltip must be hidden inmediately
			if(hide === true) {
				
				// If the timeout is set
				if(this.hidetimeout !== null) {
					
					// Clears the timeout
					clearTimeout(this.hidetimeout);
					
				}
				
				// Removes the tooltip from the dom
				this.container = this.container.detach();
				
			} else {
				
				// Sets the timeout
				this.hidetimeout = setTimeout(this.hide.bind(this, [null, true]), this.options.hideafter);
				
			}
			
		},
		
		/**
		 * Destroys the tooltips intance
		 * @method destroy
		 * @memberOf Tooltips
		 * @public
		 */
		
		destroy : function() {
			
			// Hides the tooltip
			this.hide(null, true);
			
			// Unhooks the hide event
			this.element.off('mouseout', this.hide.bind(this));
			
			// Removes the element from the dom
			this.container.remove();
			
		}
		
	}, CRD.ClassUtils);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(Tooltip, 'tooltip', storage.main);
	
	// Return the object reference
	return Tooltips;
	
})();