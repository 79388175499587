/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.BreakpointDOMActions = (function() {
	"use strict";
	
	// Variables
	var storage = {
			main     : 'crd.modifiable',
			html     : {
				main   : 'dom-modifiable',
				prefix : 'dom-target-{breakpoint}'
			}
		}, // Data and HTML storage
		extend = jQuery.extend; // Extend shortcut
	
	/**
	 * BreakpointDOMActions
	 * Modifies DOM each time the breakpoint changes.
	 * Sentences:
	 * - after:ANY_SELECTOR
	 * - before:ANY_SELECTOR
	 * - appendTo:ANY_SELECTOR
	 * - prependTo:ANY_SELECTOR
	 * @class
	 * @param {Object} options - Options to override default breakpoint actions options.
	 * @return {BreakpointDOMActions}
	 */
	
	function BreakpointDOMActions(options) {
		
		// If singleton has not been created
		if(BreakpointDOMActions.prototype.instance === null) {
			
			// Set options and events
			this.setOptions(options);
			
			// Run main script
			this.BreakpointDOMActions();
			
			// Sets singleton
			BreakpointDOMActions.prototype.instance = this;
			
		}
		
		// Returns singleton instance
		return BreakpointDOMActions.prototype.instance;
		
	}
	
	// BreakpointDOMActions prototype implements ClassUtils
	BreakpointDOMActions.prototype = extend({
		
		/**
		 * Singleton instance object
		 * @property {Object} instance - Singleton pattern instance
		 */
		
		instance : null,
		
		/**
		 * Default options
		 * @property {Object} defaults - Default options
		 */
		
		defaults : {
		
		},
		
		/**
		 * Initializes the breakpoint spy
		 * @constructor
		 * @memberof BreakpointActions
		 * @public
		 */
		
		BreakpointDOMActions : function() {
			
			// Create handlers for BreakpointSpy object
			this.switchHandler = function(e, changed, breakpoint, hdpi, spy) {
				this.performActions(changed, breakpoint, hdpi, spy);
			}.bind(this);
			
			// Hooks to BreakpointSpy change event
			jQuery(document)
				.on('crd.breakpointspy.always', this.switchHandler);
		
		},
		
		/**
		 * Executes specific breakpoint dom modifications
		 * @param {boolean} changed       - Breakpoint has changed since last execution?
		 * @param {string}  breakpoint    - Current breakpoint
		 * @param {boolean} hdpi          - Is HDPI?
		 * @param {CRD.BreakpointSpy} spy - BreakpointSpy instance
		 * @memberof BreakpointActions
		 */
		
		performActions : function(changed, breakpoint, hdpi, spy) {
			
			// Variables
			var modifiable, x, max, item, data, current, func;
			
			// If the breakpoint has changed
			if(changed === true) {
				
				// Select all modifiable objects
				modifiable = jQuery('[data-'+ storage.html.main +']');
				
				// For each modifiable element
				for(x = 0, max = modifiable.length; x < max; x = x + 1) {
					
					// Initializes the modifiable instance
					item = new Modifiable(modifiable[x]);
					
					// Gather the data for the element
					data = item.gatherData();
					
					// Gets the closest function
					current = spy.getCloser(data);
					
					// Set the function to execute
					func = current.src === false ? item.reset : current.src;
					
					// Executes the function
					func.apply(item);
					
				}
				
			}
			
		}
		
	}, CRD.ClassUtils);
	
	/**
	 * Modifiable
	 * @class
	 * @param {String|Object} element - Modifiable element
	 * @return {Modifiable}
	 */
	
	function Modifiable(element) {
		
		// Set the element
		element = jQuery(element);
		
		// Variables
		var self = jQuery(element).data(storage.main), // Data storage reference
			previous, parent;
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Selects the previous element
			previous = element.prev();
			
			// Selects the previous element
			parent = element.parent();
			
			/**
			 * Element
			 * @property {Object} element - Element
			 */
			
			this.element = element;
			
			/**
			 * Function to return the element to its original dom position
			 * @property {Function} original - Original element placement returning function
			 */
			
			this.original = function() {
				
				// If the element has a previous element
				if(previous.length > 0) {
					
					// Inserts the element after the previous element
					previous.after(element);
					
				} else {
					
					// Inserts the element on its parent
					parent.prepend(element);
					
				}
				
			};
			
			/**
			 * Dom mofification callbacks
			 * @property {Object} data - Object containing the callback for each breakpoint
			 */
			
			this.data = {};
			
			// Stores the object reference in the element storage
			element.data(storage.main, this);
			
		}
		
		// Return the instance
		return self;
		
	}
	
	// Modifiable prototype implements ClassUtils
	Modifiable.prototype = extend({
		
		/**
		 * Returns the element to its original position
		 * @method reset
		 * @memberof Modifiable
		 * @public
		 */
		
		reset : function() {
			
			// Resets the elemet to its original position
			this.original.apply(this);
			
		},
		
		/**
		 * Gather all bereakpint data
		 * @method gatherData
		 * @return {Object}
		 * @memberof Modifiable
		 * @public
		 */
		
		gatherData : function() {
			
			// If no data gathered yet
			if(Object.getOwnPropertyNames(this.data).length === 0) {
				
				// Variables
				var breakpoints = [
						'xs',
						'sm',
						'md',
						'lg',
						'xl'
					], // Available breakpoints
					data        = {}, // Gathered data
					value, // Value for gathered data
					parsed, // parsed value
					x, max; // Loop vars
				
				// For each breakpoint
				for(x = 0, max = breakpoints.length; x < max; x = x + 1) {
					
					// Get the data value
					value = this.element.data(storage.html.prefix.substitute({ 'breakpoint' : breakpoints[x] }));
					
					// If data exists
					if(typeof value !== 'undefined') {
						
						// Parses the value
						parsed = this.parseValue(value);
						
						// If the parsed value is valid
						if(parsed !== false) {
							
							// Sets image source for breakpoint
							data[breakpoints[x]] = parsed;
							
						}
						
					}
					
				}
				
				// Sets the data
				this.data = data;
				
			}
			
			// Returns data object
			return this.data;
			
		},
		
		/**
		 * Parse the defined value and returns the corresponding insertuion callback
		 * @method parseValue
		 * @param {String} value
		 * @return {Function|Boolean}
		 * @memberof Modifiable
		 * @public
		 */
		
		parseValue : function(value) {
			
			// Variables
			var index = value.indexOf(':'),
				mode, selector;
			
			// If no mode defined
			if(index === -1) return false; // :(
			
			// Sets the mode
			mode = value
				.substr(0, index)
				.replace('To', '');
			
			// Sets the selector
			selector = value.substr(index + 1, value.length);
			
			// Returns the function
			return function() {
				
				// Gets the element to its position
				jQuery(selector)[mode](this.element);
				
			}.bind(this);
			
		}
	
	}, CRD.ClassUtils);
	
	// Return the object reference
	return BreakpointDOMActions;
	
})();