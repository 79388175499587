/**
 * CRD namespace definition
 * @namespace
 */

namespace('CRD');

// Creates the namepsace for the object
CRD.Layout = (function() {
	"use strict";
	
	// Variables
	var storage = {
			main : 'crd.layout',
			html : 'layout-options'
		}, // Storage
		constants = {
			BoxSizing : {
				BORDER_BOX  : 'border-box',
				CONTENT_BOX : 'content-box'
			},
			Events    : {
				RESIZE  : 'crd.layout.resize',
				DISABLE : 'crd.layout.disable'
			},
			Storage   : {
				MAIN : storage.main,
				HTML : storage.html
			}
		}; // Constants
	
	/**
	 * Gets inner size of an element
	 * @param {Object|Sting} element - Element to measure
	 * @return {{width, height}}
	 * @private
	 */
	
	function getInnerSize(element) {
		
		// Element
		element = jQuery(element);
		
		// Returns an object containing the inner width and height of the element
		return {
			'width'  : element.innerWidth(),
			'height' : element.innerHeight()
		};
		
	}
	
	/**
	 * Gets the element padding
	 * @param {Object|String} element - Element to measure
	 * @return {{top, right, bottom, left}}
	 * @private
	 */
	
	function getPadding(element) {
		
		// Element
		element = jQuery(element);
		
		// Variables
		var paddings = {
			'top'    : 0,
			'right'  : 0,
			'bottom' : 0,
			'left'   : 0
		};
		
		// Variables
		['top', 'right', 'bottom', 'left'].each(function(i, key) {
			
			// Gets the element padding
			paddings[key] = parseFloat(element.css('padding-' + key));
			
		});
		
		// Returns the object padding
		return paddings;
	}
	
	/**
	 * Get the Layout options from the html tag atributes.
	 * @param {String|Object} element - Element to get the Layout options
	 * @return {Object}
	 * @see Layout.proptotype.default for complete set of options
	 * @private
	 */
	
	function getOptions(element) {
		
		// Variables
		var options;
		
		// Element
		element = jQuery(element);
		
		// Get the element options (html data atributed options)
		options = element.data(storage.html);
				
		// If defined, element options are JSON parsed (cleaned quotes first)
		options = typeof options !== 'undefined' && options !== '' ?
			jQuery.parseJSON(options.replace(/'/g, '"')) :
			{};
			
		// (:
		return options;
		
	}
	
	/**
	 * Layout
	 * @class
	 * @param {Object|String} element - Element to apply the layout object/calculations
	 * @param {Object}        options - Options to override default layout options.
	 * @return {Layout}
	 */
	
	function Layout(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data(storage.main); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			this.setOptions(options);
			
			/**
			 * Element to use as layout element
			 * @property {Object} element - Element
			 */
			
			this.element = element;
			
			/**
			 * State of the layout
			 * @property {Boolean} disabled - Disabled state
			 */
			
			this.disabled = false;
			
			// Initializes the object
			this.Layout();
			
			// Stores the object reference in the element storage
			this.element.data(storage.main, this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	// Layout prototype implements ClassUtils
	Layout.prototype = jQuery.extend({
		
		/**
		 * Default options
		 * @property {Object}          defaults                 - Default options
		 * @property {String}          defaults.initState       - Initial state (opened|closed)
		 */
		
		defaults : {
			width          : null,
			height         : null,
			top            : 0,
			right          : 0,
			bottom         : 0,
			left           : 0,
			minWidth       : 0,
			maxWidth       : -1,
			minHeight      : 0,
			maxHeight      : -1,
			position       : 'absolute',
			propagate      : true,
			disabledBefore : 767,
			delay          : 50,
			childsDelay    : 10,
			boxSizing      : constants.BoxSizing.BORDER_BOX
		},
		
		/**
		 * Initializes Layout object
		 * @constructor
		 * @memberof Layout
		 * @public
		 */
		
		Layout : function() {
			
			// Bind the resize function to the element
			this.element.resize = this.resize.bind(this);
			
			// Attach events to trigger the resize
			this.attachEvents();
			
		},
		
		/**
		 * Attach the events to trigger the resize
		 * @method attachEvents
		 * @listen window.load
		 * @listen window.resize
		 * @memberof Layout
		 * @public
		 */
		
		attachEvents : function() {
			
			// If the element is the first element in the chain (of nested resizable elements)
			if(this.element.parent().is(jQuery(document.body))) {
				
				// Creates the resize handler
				this.resizeHandler = function(e) {
					this.resize(true);
				}.bind(this);
				
				// Hooks to the window events
				jQuery(window).on({
					'load'   : this.resizeHandler,
					'resize' : this.resizeHandler
				});
				
			}
			
			// (:
			return this;
			
		},
		
		/**
		 * Detach the events that triggers the resize
		 * @method detachEvents
		 * @memberof Layout
		 * @public
		 */
		
		detachEvents : function() {
			
			// If the handler has been created
			if(typeof this.resizeHandler !== 'undefined') {
				
				// Unhooks to the window events
				jQuery(window).off({
					'load'   : this.resizeHandler,
					'resize' : this.resizeHandler
				});
				
			}
			
			// (:
			return this;
			
		},
		
		/**
		 * Resizes the element
		 * to-do : be able to calculate over defined percentages
		 * @method resize
		 * @param {Boolean} main - Is this the main node of the resiize chain?
		 * @fires crd.layout.resize
		 * @memberOf Layout
		 * @public
		 */
		
		resize : function(main, count) {
			
			// Variables
			var parentSize = getInnerSize(this.element.parent().prop('tagName').toLowerCase() === 'body' ?
					jQuery(window) :
					this.element.parent()), // Size of the parent element
				padding, width, height, top, left, dimensions;
			
			// If the resize is called by the main node
			if(main === true) {
				
				// If layout must disable at a defined size and that size has been reached
				if(this.options.disabledBefore > 0 && parentSize.width <= this.options.disabledBefore) {
					
					// Disable the layout
					// fix-me : interface must disable only once, and then be enabled again if needed
					this.disable();
					
					// (: (we don't want the layout process to continue)
					return;
					
				} else {
					
					// Set the element as enabled (or not disabled)
					this.disabled = false;
					
					// Count
					count = count ? count : 1;
					
					// Executes the resize
					this.resize(false);
					
					if(count < 3) {
						
						// Clear the resize timeout
						clearTimeout(this.resizeTimeout);
						
						// Sets the recirsive function execution
						this.resizeTimeout = this.resize.delay(this.options.delay, this, [true, count + 1]);
						
					}
					
				}
				
			} else {
				
				// If left position is defined
				if(this.options.left !== null) {
					
					// Sets left position
					left = this.options.left;
					
					// If right position is not defined
					if(this.options.right === null) {
						
						// If width is not defined
						if(this.options.width === null) {
							
							// Sets the width as the total width of the conainer minus the left offset
							width = parentSize.width - left;
							
							// If width is lower than minimum width
							if(width < this.options.minWidth) {
								
								// Sets width as minimum width
								width = this.options.minWidth;
								
							}
							
							// Is maximum width is defined and width is higher than maximum width
							if(this.options.maxWidth >= 0 && width > this.options.maxWidth) {
								
								// Sets the width as the defined maximum width
								width = this.options.maxWidth;
								
							}
							
						}
						
						// If width is defined
						else {
							
							// Otherwise sets the width as defined
							width = this.options.width;
							
						}
						
					}
					
					// If right position is defined
					else {
						
						// Is width is not defined
						if(this.options.width === null) {
							
							// Sets the width as the container element minus the left position minus the right position
							width = parentSize.width - left - this.options.right;
							
							// If width is lower than minimum width
							if(width < this.options.minWidth) {
								
								// Sets width as minimum width
								width = this.options.minWidth;
								
							}
							
							// If maximum width is defined and width is higher than maximum width
							if(this.options.maxWidth >= 0 && width > this.options.maxWidth) {
								
								// Sets the width as the defined maximum width
								width = this.options.maxWidth;
								
							}
							
						}
						
						// If width is defined
						else {
							
							// Otherwise sets the width as defined
							width = this.options.width;
							
						}
						
					}
					
				}
				
				// If left position is not defined
				else {
					
					// If right position is not defined
					if(this.options.right === null) {
						
						// If width is not defined
						if(this.options.width === null) {
							
							// Sets the left position as zero (cause is not defined)
							left = 0;
							
							// Sets the width as total available width of the parent element
							width = parentSize.width;
							
							// If maximum width is defined and width is higher than maximum width
							if(this.options.maxWidth >= 0 && width > this.options.maxWidth) {
								
								// Sets the left and width so the element will be centered according to its parent (cause left and right is not defined and a width - maximum widtg - is defined)
								left  = left + parseInt(width - this.options.maxWidth, 10) / 2;
								width = this.options.maxWidth;
								
							}
							
						}
						
						// If width is defined
						else {
							
							// Sets the width and left position, so left is not a negative value
							width = this.options.width;
							left  = Math.max(0, parseInt((parentSize.width - width) / 2, 10));
						}
						
					}
					
					// If right position is defined
					else {
						
						// If width is defined
						if(this.options.width !== null) {
							
							// Sets the width and left position, so left is not a negative value
							width = this.options.width;
							left  = Math.max(0, parentSize.width - width - this.options.right);
							
						} else {
							
							// Sets the width and left position
							left  = 0;
							width = parentSize.width - this.options.right;
							
							// If width is lower than minimum width
							if(width < this.options.minWidth) {
								
								// Sets width as minimum width
								width = this.options.minWidth;
								
							}
							
							// If maximum width is defined and width is higher than maximum width
							if(this.options.maxWidth >= 0 && width > this.options.maxWidth) {
								
								// Sets the left and width so the element will be aligned according to its right offset
								left  = width - this.options.maxWidth - this.options.right;
								width = this.options.maxWidth;
								
							}
							
						}
						
					}
					
				}
				
				// If top position is defined
				if(this.options.top !== null) {
					
					// Sets the defined top position
					top = this.options.top;
					
					// If bottom position is not defined
					if(this.options.bottom === null) {
						
						// If height is not defined
						if(this.options.height === null) {
							
							// Sets height as avalable height minus top position
							height = parentSize.height - top;
							
							// If height is lower than minimum height
							if(height < this.options.minHeight) {
								
								// Sets height as minimum height
								height = this.options.minHeight;
								
							}
							
							// If maximum height is defined and height is higher than maximum height
							if(this.options.maxHeight >= 0 && height > this.options.maxHeight) {
								
								// Sets the height as the defined maximum height
								height = this.options.maxHeight;
								
							}
							
						}
						
						// If height is defined
						else {
							
							// Sets the height as defined
							height = this.options.height;
							
							// If maximum height is defined and height is higher than maximum height
							if(this.options.maxHeight >= 0 && height > this.options.maxHeight) {
								
								// Sets the top and height so the element will be centered according to its parent (cause top and bottom is not defined and a height - maximum height - is defined)
								top    = height - this.options.maxHeight;
								height = this.options.maxHeight;
								
							}
						}
					}
					
					// If bottom position is defined
					else {
						
						// If height is not defined
						if(this.options.height === null) {
							
							// Sets height as available height
							height = parentSize.height - top - this.options.bottom;
							
							// If height is lower than minimum height
							if(height < this.options.minHeight) {
								
								// Sets height as minimum height
								height = this.options.minHeight;
								
							}
							
							// If maximum height is defined and height is higher than maximum height
							if(this.options.maxHeight >= 0 && height > this.options.maxHeight) {
								
								// Sets the height as the defined maximum height
								height = this.options.maxHeight;
								
							}
							
						}
						
						// If height is defined
						else {
							
							// Sets height as defined
							height = this.options.height;
							
						}
						
					}
					
				}
				
				// If top is not defined
				else {
					
					// If bottom is not defined
					if(this.options.bottom === null) {
						
						// If height is not defined
						if(this.options.height === null) {
							
							// Sets top as zero
							top = 0;
							
							// Sets height as available height
							height = parentSize.height;
							
							// If height is lower than minimum height
							if(height < this.options.minHeight) {
								
								// Sets height as minimum height
								height = this.options.minHeight;
								
							}
							
							// If maximum height is defined and height is higher than maximum height
							if(this.options.maxHeight >= 0 && height > this.options.maxHeight) {
								
								// Sets top and height so the element will be vertically centered to its parent
								top    = parseInt((parentSize.height - this.options.maxHeight) / 2, 10);
								height = this.options.maxHeight;
								
							}
							
						}
						
						// If height is defined
						else {
							
							// Sets top and height so the element will be vertically centered to its parent
							height = this.options.height;
							top    = Math.max(0, parseInt((parentSize.height - height) / 2, 10));
							
						}
						
					}
					
					// If bottom is defined
					else {
						
						// If height is defined
						if(this.options.height !== null) {
							
							// Sets the top and height according to de defined height and bottom position
							height = this.options.height;
							top    = Math.max(0, parentSize.height - height - this.options.bottom);
							
						}
						
						// If height is not defined
						else {
							
							// Sets the top position
							top = 0;
							
							// Sets the height as available height minus bottom position
							height = parentSize.height - this.options.bottom;
							
							// If height is lower than minimum height
							if(height < this.options.minHeight) {
								
								// Sets height as minimum height
								height = this.options.minHeight;
								
							}
							
							// If maximum height is defined and height is higher than maximum height
							if(this.options.maxHeight >= 0 && height > this.options.maxHeight) {
								
								// Sets top and height according to available height and bottom position
								top    = parentSize.height - this.options.maxHeight - this.options.bottom;
								height = this.options.maxHeight;
								
							}
							
						}
						
					}
					
				}
				
				// If box model is not set to 'border-box'
				if(this.options.boxSizing !== constants.BoxSizing.BORDER_BOX) {
					
					// Gets the element padding
					padding = getPadding(this.element);
					
					// Removes padding from element width and height
					width  = width - padding.left - padding.right;
					height = height - padding.top - padding.bottom;
					
				}
				
				// Sets the dimensions for the element
				dimensions = {
					'top'    : top,
					'left'   : left,
					'width'  : width,
					'height' : height
				};
				
				// Sets the element dimensions
				this.element.css(jQuery.extend(dimensions, { 'position' : this.options.position }));
				
				// If resize must propagate
				if(this.options.propagate) {
					
					// Propagate resize to resizeable child elements
					this.propagate();
					
				}
				
				/**
				 * Triggers the resize event
				 * @event crd.layout.resize
				 */
				
				this.dispatch(constants.Events.RESIZE, [
					dimensions,
					this.element,
					this
				]);
				
				/**
				 * Triggers the resize event on the element
				 * @event crd.layout.resize
				 */
				
				this.dispatch(constants.Events.RESIZE, [
					dimensions,
					this
				], this.element);
					
				
			}
			
		},
		
		/**
		 * Propagate the resize to child elements
		 * @method propagate
		 * @memberOf Layout
		 * @public
		 */
		
		propagate : function() {
			
			// Variables
			var self = this;
			
			// For each element child
			this.element.children().each(function(i, child) {
				
				// Element
				child = jQuery(child);
				
				// Variables
				var obj = child.data(storage.main);
				
				// If a Layout object is binded to the child element
				if(typeof obj !== 'undefined') {
					
					// Triggers the element resize
					obj.resize.delay(self.options.childsDelay, obj);
					
				}
				
			});
			
		},
		
		/**
		 * Disables the layout for the element (resets position and dimensions)
		 * @method disable
		 * @fires crd.layout.disable
		 * @memberOf Layout
		 * @public
		 */
		
		disable : function() {
			
			// Sets the element as disabled
			this.disabled = true;
			
			// Unset all
			this.element.css({
				'position' : '',
				'top'      : '',
				'left'     : '',
				'width'    : '',
				'height'   : ''
			});
			
			// If disabling must propagate
			if(this.options.propagate) {
				
				// For each element child
				this.element.children().each(function(i, child) {
					
					// Element
					child = jQuery(child);
					
					// Variables
					var obj = child.data(storage.main);
					
					// If a Layout object is binded to the child element
					if(typeof obj !== 'undefined') {
						
						// Triggers the element resize
						obj.disable.apply(obj);
						
					}
					
				});
				
			}
			
			/**
			 * Triggers the disable event
			 * @event crd.layout.disable
			 */
			
			this.dispatch(constants.Events.DISABLE, [
				this.element,
				this
			]);
			
			/**
			 * Triggers the disable event on the element
			 * @event crd.layout.disable
			 */
			
			this.dispatch(constants.Events.DISABLE, [
				this
			], this.element);
			
		},
		
		/**
		 * Get the options
		 * @method getOptions
		 * @param {String} from
		 * @return {Object}
		 * @memberOf Layout
		 * @public
		 */
		
		getOptions : function(from) {
			
			// Variables
			var options;
			
			// Default from state
			from = from || storage.html;
			
			// Depending on the data storage type from which the data should be gathered
			switch(from) {
				
				// Object options
				case storage.main:
					
					// Set the options to return
					options = this.options;
					
					break;
				
				// HTML atributed options
				case storage.html:
					
					// Get the element options (html data atributed options)
					options = getOptions(this.element);
					
					break;
				
			}
			
			// (:
			return options;
			
		}
		
	}, CRD.ClassUtils);
	
	// Set object constants
	CRD.Utils.setConstants(Layout, constants);
	
	// Defines the jQuery helper
	CRD.Utils.defineHelper(Layout, 'layout', storage.main);
	
	// Populate some of the private functions
	Layout.getOptions = getOptions;
	
	// Populate some helpers
	jQuery.fn.extend(jQuery.fn, {
		
		/**
		 * Gets inner size of the first element in the match
		 * @method getInnerSize
		 * @param {Object|Sting} element - Element to measure
		 * @return {{width, height}}
		 * @memberOf jQuery.fn
		 * @public
		 */
		
		getInnerSize : function() {
			
			// Get the size of the first element in the match
			return getInnerSize(this[0]);
			
		},
		
		/**
		 * Gets the element padding
		 * @method getPadding
		 * @param {Object|String} element - Element to measure
		 * @return {{top, right, bottom, left}}
		 * @memberOf jQuery.fn
		 * @public
		 */
		
		getPadding : function() {
			
			// Get the padding for the first element in the match
			return getPadding(this[0]);
			
		}
		
	});
	
	// Retruns the constructor
	return Layout;
	
})();